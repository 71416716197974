import axios from 'axios'

//const BASE_URL = 'http://localhost:8000/'
// const BASE_URL = 'http://20.206.201.63:8000/'
// const BASE_URL = 'https://homolog-orquestrador.postei.ai/'

// const BASE_URL = 'http://orquestrador:8000/'

const BASE_URL = import.meta.env.VITE_ORQUESTRADOR_URL

export const postVoiceCampaign = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/voice-campaign/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postVoiceCampaign', error.response.data)
      return error
    })
}

export const postGenerateImages = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/generate-images/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postGenerateImages', error.response.data)
      return error
    })
}

export const postGenerateArt = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/generate-art/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postGenerateArt', error.response.data)
      return error
    })
}

export const postGenerateArtTemplate = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/generate-art-templates/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postGenerateArtTemplate', error.response.data)
      return error
    })
}

export const postGenerateArtAi = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/generate-art/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postGenerateArtAi', error.response.data)
      return error
    })
}

export const postGenerateArtEditTemplate = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/edit-art-templates/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postGenerateArtEditTemplate', error.response.data)
      return error
    })
}

export const postHeadline = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/headline/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postHeadline', error.response.data)
      return error
    })
}

export const postRemoveBackground = async (formData) => {
  return await axios
    .post(`${BASE_URL}api/v1/remove-background/`, formData)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postRemoveBackground', error.response.data)
      return error
    })
}

export const postAddLogoToImage = async (formData) => {
  return await axios
    .post(`${BASE_URL}api/v1/add-logo-to-images/`, formData)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postAddLogoToImage', error.response.data)
      return error
    })
}

export const postGenerateMenu = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/generate-menu/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postGenerateMenu', error.response.data)
      return { data: error }
      // throw error
    })
}

export const postPromptGPT = async (body) => {
  return await axios
    .post(`${BASE_URL}api/v1/chat/`, body)
    .then((response) => {
      return { data: response }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postGenerateArtTemplate', error.response.data)
      return error
    })
}
