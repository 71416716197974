import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { CalendarPublication } from '../../components/CalendarPublucation/index'
import { DisplaySchedule } from '../../components/DisplaySchedule'
import { PopUp } from '../../components/PopUp'
import { PublicationService } from '../../services'

import { Loading } from '../../components/Loading'
import { useClient } from '../../hooks/useClient'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

function convertDateFormat(dateString) {
  var parts = dateString.split('-')

  var year = parseInt(parts[0], 10)
  var month = parseInt(parts[1], 10)
  var day = parseInt(parts[2], 10)

  return month + '-' + day + '-' + year
}

const formatDateName = (dateString) => {
  try {
    const date = new Date(dateString)
    if (!isNaN(date)) {
      return date.toLocaleDateString('pt-BR', {
        day: 'numeric',
        month: 'long',
        // year: 'numeric',
      })
    } else {
      return ''
    }
  } catch (error) {
    return ''
  }
}

function getDaysOfMonth(year, month) {
  let date = new Date(year, month, 1)
  let days = []
  while (date.getMonth() === month) {
    days.push(date.getDate())
    date.setDate(date.getDate() + 1)
  }
  return days.map(
    (day) =>
      `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(
        2,
        '0'
      )}`
  )
}

function calculateNewDayPublication(days, publicationId, newDate) {
  const dayIndex = days.findIndex(
    (day) =>
      day && day.content.find((publication) => publication.id === publicationId)
  )
  if (dayIndex === -1 || !days[dayIndex]) return
  const originDate = normalizeDate(days[dayIndex].date)
  const targetDate = normalizeDate(newDate)
  const rangeDifference = calculateDaysBetweenDates(originDate, targetDate)
  const publicationIndex = days[dayIndex].content.findIndex(
    (publication) => publication.id === publicationId
  )
  const publication = days[dayIndex].content[publicationIndex]
  days[dayIndex].content.splice(publicationIndex, 1)
  if (!days[dayIndex].content.length) days[dayIndex] = undefined
  const newDayIndex = dayIndex + rangeDifference
  if (!days[newDayIndex]) {
    days[newDayIndex] = { date: newDate, content: [publication] }
  } else {
    if (!days[newDayIndex]) return
    days[newDayIndex].content.push(publication)
  }
  
  return days
}

function calculateDaysBetweenDates(originDate, targetDate) {
  const startDate = new Date(originDate).getTime()
  const endDate = new Date(targetDate).getTime()
  const differenceInMilliseconds = endDate - startDate
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)
  return Math.round(differenceInDays) // Round to the nearest whole number
}

function normalizeDate(date) {
  const [month, day, year] = date.split('-')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

function SchedulePublication() {
  const { clientData } = useClient()

  const [selectObjectCalendar, setSelectObjectCalendar] = useState([])
  const [contentPublication, setContentPublication] = useState([])

  const [datesCalendarData, setDatesCalendarData] = useState([])

  //const [publications, setPublications] = useState([])
  const [loading, setLoading] = useState(true)

  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  const [currentDate, setCurrentDate] = useState(new Date()) // Get the current date
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())

  // useEffect(() => {
  //   const dateSchedule = location.state?.date
  //     ? location.state?.date
  //     : formatDate(new Date())
  //   const indexSchedule = location.state?.index ? location.state?.index : 0

  //   setLoading(true)

  //   const month = new Date().getMonth()
  //   const year = new Date().getFullYear()

  //   loadData(month, year, dateSchedule, indexSchedule)
  // }, [clientData])

  useEffect(() => {
    const currentDate = new Date() // Obtém a data atual

    const dateCurrent = formatDate(new Date())

    const dateSchedule = location.state?.date
      ? location.state.date
      : dateCurrent

    const indexSchedule = location.state?.index ? location.state.index : 0
    const publicationIdIndex = location.state?.publicationId
      ? location.state?.publicationId
      : null

    setLoading(true)

    const month = currentDate.getMonth()
    const year = currentDate.getFullYear()
    // const currentDate = dayjs()

    // const dateCurrent = currentDate.format('YYYY-MM-DD') // Formata a data atual (pode ajustar o formato conforme necessário)

    // const dateSchedule = location.state?.date
    //   ? dayjs(location.state.date).format('YYYY-MM-DD')
    //   : dateCurrent

    // const indexSchedule = location.state?.index ?? 0 // Utiliza '??' para valor default (ES2020)

    // setLoading(true)

    // const month = currentDate.month()
    // const year = currentDate.year()

    loadData(month, year, dateSchedule, indexSchedule, '', publicationIdIndex)
  }, [clientData])

  useEffect(() => {
    //console.log('teste de useEffect', selectObjectCalendar)
  }, [selectObjectCalendar])

  function formatDate(dateString) {
    const date = new Date(dateString)
    const day = date.getDate() // Obtém o dia
    const month = (date.getMonth() + 1).toString().padStart() // Os meses são indexados a partir de zero
    const year = date.getFullYear().toString()
    return `${month}-${day}-${year}`
  }

  const loadData = async (
    month,
    year,
    dateSchedule,
    indexSchedule,
    itemSelected,
    publicationIdIndex
  ) => {
    setLoading(true)

    
    const { data, responseStatus } =
      clientData?.uuid === undefined
        ? await PublicationService.getAllPublicationByLoginByDateNotBusinessClient(
            month + 1,
            year
          )
        : await PublicationService.getAllPublicationByLoginByDateByBusinessClientId(
            clientData?.uuid,
            month + 1,
            year
          )

    if (responseStatus === 200) {
      const lastMonth = month === 0 ? 11 : month - 1
      const lastYear = month === 0 ? year - 1 : year
      const nextMonth = month === 11 ? 0 : month + 1
      const nextYear = month === 11 ? year + 1 : year
      const daysOfLastMonth = getDaysOfMonth(lastYear, lastMonth)
      const daysOfCurrentMonth = getDaysOfMonth(year, month)
      const daysOfNextMonth = getDaysOfMonth(nextYear, nextMonth)
      const daysOfMonth = [
        ...daysOfLastMonth,
        ...daysOfCurrentMonth,
        ...daysOfNextMonth,
      ]

      const calendarData = daysOfMonth.map((day) => {
        const publication = data.find((publication) => {
          return publication?.publicationDateDay === day
        })

        if (publication) {
          return {
            date: convertDateFormat(publication?.publicationDateDay),
            content: publication?.data?.map((item) => {
              const publicationImages = [
                {
                  id: item?.publicationImageUrlName,
                  url: item?.publicationImageUrl,
                },
              ]

              return {
                id: item.uuid,
                title: item?.headline,
                status: item?.status,
                logo: '',
                client: '',
                description: item?.headline,
                date: formatDateName(item?.publicationDate),
                hour:
                  dayjs(item?.publicationDate).hour() +
                  ':' +
                  dayjs(item?.publicationDate)
                    .minute()
                    .toString()
                    .padStart(2, '0'),
                socialTypes: item?.socialTypes,
                // pinterest: false,
                // linkedin: false,
                // facebook: false,
                // instagram: false,
                // tiktok: false,
                copy: item?.legend,
                objective: item?.objective,
                images: publicationImages,
                generateArtJson: item.generateArtJson,

                videoUrl: item?.publicationMovieUrl,
              }
            }),
          }
        }
      })

      const today = new Date()

      const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })

      // Substituir as barras por hífens
      const formattedDateWithHyphens = formattedDate.replace(/\//g, '-')

      setDatesCalendarData(calendarData)

      if (dateSchedule) {
        for (let index = 0; index < calendarData.length; index++) {
          if (calendarData[index]?.date === dateSchedule) {
            if (indexSchedule !== undefined) {
              const indexCalendar = calendarData[index]?.content.findIndex(
                (item) => item.id === publicationIdIndex
              )

              setSelectObjectCalendar(
                calendarData[index]?.content[indexCalendar]
              )
            } else {
              const findItemSelected = calendarData[index]?.content.find(
                (item) => item.id === itemSelected
              )

              if (findItemSelected) {
                setSelectObjectCalendar(findItemSelected)
              }
            }
          } else {
            console.log('error', indexSchedule)
          }
        }
      } else {
        for (let index = 0; index < calendarData.length; index++) {
          if (calendarData[index]?.date === formattedDateWithHyphens) {
            setSelectObjectCalendar(calendarData[index]?.content[0])
          }
        }
      }
    }

    setLoading(false)
  }

  const storage = JSON.parse(window.localStorage.getItem('userData')).userEmail
  const userInfo = {
    username: storage, // REQUIRED, must be unique
  }

  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%', height: '100%' }}
    >
      <div className={styles.page}>
        <Loading enable={loading} />
        <div className={styles.container_calendar_area}>
          <CalendarPublication
            setSelectObjectCalendar={setSelectObjectCalendar}
            selectObjectCalendar={selectObjectCalendar}
            dataPublication={datesCalendarData}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setContentPublication={setContentPublication}
            setLoading={setLoading}
            loadData={loadData}
            setDatesCalendarData={setDatesCalendarData}
          />
        </div>

        {selectObjectCalendar && (
          <div className={styles.container_publication_area}>
            <DisplaySchedule
              selectObjectCalendar={selectObjectCalendar}
              loadData={loadData}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        )}

        {isOpen && (
          <PopUp
            setSelectObjectCalendar={setSelectObjectCalendar}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setContentPublication={setContentPublication}
            contentPublication={contentPublication}
          />
        )}
      </div>
    </motion.div>
  )
}

export default SchedulePublication
