/* eslint-disable react/prop-types */
import styles from './styles.module.css'

import {
  NavigateArrowRigth,
  NumberOne,
  NumberThree,
  NumberTwo,
  Refresh,
} from '../../../assets/svgs/icons'
import { useState, useEffect } from 'react'

import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import SimpleEditionModal from '../../../components/SimpleEditionModal'
import PropTypes from 'prop-types'

import { Loading } from '../../../components/Loading'

import {
  BrandingService,
  BusinessService,
  ProductService,
  PythonService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services/'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',
}

function hexToRgb(hex) {
  // Remove o símbolo '#' do início, se presente
  hex = hex.replace(/^#/, '')

  // Verifica se o valor hexadecimal é válido
  if (/^[0-9A-Fa-f]{6}$|^[0-9A-Fa-f]{3}$/.test(hex)) {
    // Se o valor é uma representação de 3 dígitos, expanda para 6 dígitos
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(function (char) {
          return char + char
        })
        .join('')
    }

    // Divide o valor em pares de dígitos (R, G, B)
    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)

    return `(${r},${g},${b})`
  } else {
    // Retorna uma mensagem de erro para valores inválidos
    return `(${0},${0},${0})`
  }
}

function StepThirdPass({
  setStep,
  setIaData,
  iaData,
  setImageUrl,
  imageUrl,
  imageLayers,
  setImageLayers,
  headlineText,
  setHeadlineText,
  imageGaleryArray,
  imageAIArray,
  imageUploadArray,
  imageProductsArray,
  setHeadlineList,
  setLegendList,

  // purposeOfPublicationRef,
  // publicationFormatRef,
  productRef,
  targetAudienceRef,
  // iWantPublicationRef,
}) {
  const [isModalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [headlineSubtitle, setHeadlineSubtitle] = useState('')
  const [responseBusinesData, setResponseBusinesData] = useState({})
  const [fontToRender, setFontToRender] = useState('')
  const { t } = useTranslation()

  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productTargetAudienceOptions, setProductTargetAudienceOptions] =
    useState([])

  useEffect(() => {
    if (iaData.length === 0) {
      fetchData()
    }
    loadProductServiceData()
    loadtargetAudienceData()
  }, [iaData])

  const fetchData = async () => {
    setLoading(true)

    const loadbusiness = await loadBusinessData()

    await getIaHeadline(loadbusiness)
    await getIaImages(loadbusiness)
    await loadFontImages()
    setLoading(false)
  }

  const loadProductServiceData = async () => {
    const { data, responseStatus } = await ProductService.getProduct()
    if (responseStatus === 200)
      setProductTypeOptions(
        data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      )
  }

  const loadtargetAudienceData = async () => {
    const { data, responseStatus } =
      await TargetAudienceService.getTargetAudiences()
    if (responseStatus === 200)
      setProductTargetAudienceOptions(
        data?.map((item) => ({
          label: item.profession,
          value: item.uuid,
        }))
      )
  }

  const loadFontImages = async () => {
    const { data } = await BrandingService.getAllBrandingByBusiness()

    const fontData = data.at(0).fontsBase64.fontTitleBase64
    const fontName = fontData.name.replace(/\..+$/, '')
    const dynamicFont = new FontFace(
      fontName,
      `url(data:font/woff2;base64,${fontData.data})`
    )

    await dynamicFont.load().then((font) => document.fonts.add(font))
    setFontToRender(fontName)
  }

  const loadBusinessData = async () => {
    const productInfos = await ProductService.getProduct()
    const brandingInfos = await BrandingService.getAllBranding()
    const voiceToneInfos = await VoiceToneService.getAllVoiceTone()
    const businessInfos = await BusinessService.getBusinessByLogin()
    const targetAudiencesInfos =
      await TargetAudienceService.getAllTargetAudience()

    const obj = {
      productInfos: productInfos,
      brandingInfos: brandingInfos,
      voiceToneInfos: voiceToneInfos,
      businessInfos: businessInfos,
      targetAudiencesInfos: targetAudiencesInfos,
    }

    setResponseBusinesData(obj)

    return obj
  }
  const numImage = 4
  const headlineListLocal = []
  const legendListLocal = []
  const getIaHeadline = async (loadbusiness) => {
    let businessData = loadbusiness

    const totalMainImages =
      imageGaleryArray?.length +
      imageAIArray?.length +
      imageUploadArray?.length +
      imageProductsArray?.length
    const numHeadline = numImage * totalMainImages

    // console.log('purposeOfPublicationRef', purposeOfPublicationRef)
    // console.log('publicationFormatRef', publicationFormatRef)
    // console.log('productRef', productRef)
    // console.log('targetAudienceRef', targetAudienceRef)
    // console.log('iWantPublicationRef', iWantPublicationRef)

    if (targetAudienceRef.current.label == 'Nenhum') {
      targetAudienceRef.current = undefined
    }
    if (productRef.current.label == 'Nenhum') {
      productRef.current = undefined
    }

    let bodyHeadline = {
      publications_number: numHeadline,
      niche: businessData.businessInfos?.data[0].BusinessNiche?.name,
      subniche: businessData.businessInfos?.data[0].subNiche,
      publication_purpose: 'divulgar',
      description_business: businessData.businessInfos?.data[0].description,
      voice_tone: businessData.voiceToneInfos?.data[0].tone,
      // social_class: 'Classe A',
      social_class: targetAudienceRef.current
        ? targetAudienceRef.current.socialClassValue
        : '',
      // gender: businessData.targetAudiencesInfos?.data[0]?.genderValue,
      gender: targetAudienceRef.current
        ? targetAudienceRef.current?.genderValue
        : '',
      // profession: businessData.targetAudiencesInfos?.data[0].profession,
      profession: targetAudienceRef.current
        ? targetAudienceRef.current?.profession
        : '',
      // name_product: businessData.productInfos?.data[0].name,4
      name_product: productRef.current ? productRef.current?.name : '',
      // product_type: businessData.productInfos?.data[0].productTypeValue,
      product_type: productRef.current
        ? productRef.current?.productTypeValue
        : '',
      // product_description: businessData.productInfos?.data[0].description,
      product_description: productRef.current
        ? productRef.current?.description
        : '',
      // value: businessData.productInfos?.data[0].value,
      value: productRef.current ? productRef.current?.price : '',
      // promotional_value: businessData.productInfos?.data[0].promotionalValue,
      promotional_value: productRef.current
        ? productRef.current?.promotionalValue
        : '',
    }

    const headline = await PythonService.postHeadline(bodyHeadline)

    await headline?.data?.data.forEach((element) => {
      headlineListLocal.push(element.headline)
      legendListLocal.push(element.legenda)
    })

    await setHeadlineList(headlineListLocal)
    await setLegendList(legendListLocal)

    await setHeadlineText(headline?.data?.data[0]?.headline)
  }

  const getIaImages = async (loadbusiness) => {
    //return
    let businessData = loadbusiness

    let bodyGenerateArt = {
      art_width: 1080,
      art_height: 1350,
      text: [
        // ['KITS DE ID. VISUAL', 'FONTE PRINCIPAL, FONTE SECUNDÁRIA E CORES'],
        [headlineListLocal[0]],
      ],
      categoria: [
        // ['titulo', 'subtitulo']
        ['titulo'],
      ],
      publications_number: 1,
      niche: businessData.businessInfos?.data[0].BusinessNiche?.name,
      subniche: '', //!Remover posteriormente
      publication_purpose: 'divulgar', //?Sempre assim
      description_business: businessData.businessInfos?.data[0].description,
      voice_tone: businessData.voiceToneInfos?.data[0].tone,
      // social_class: 'Classe B',
      social_class: targetAudienceRef.current
        ? targetAudienceRef.current.socialClassValue
        : '',
      gender: targetAudienceRef.current
        ? targetAudienceRef.current?.genderValue
        : '', //!Lembrar de adicionar a opção 'Ambos'
      // profession: businessData.targetAudiencesInfos?.data[0].profession,
      profession: targetAudienceRef.current
        ? targetAudienceRef.current?.profession
        : '',
      // name_product: businessData.productInfos?.data[0].name,
      name_product: productRef.current ? productRef.current?.name : '',
      // product_type: businessData.productInfos?.data[0].productTypeValue,
      product_type: productRef.current
        ? productRef.current?.productTypeValue
        : '',
      // product_description: businessData.productInfos?.data[0].description,
      product_description: productRef.current
        ? productRef.current?.description
        : '',
      // value: businessData.productInfos?.data[0].value,
      value: productRef.current ? productRef.current?.price : '',
      // promotional_value: businessData.productInfos?.data[0].promotionalValue,
      promotional_value: productRef.current
        ? productRef.current?.promotionalValue
        : '',
      // produto: businessData.productInfos?.data[0].name,
      produto: productRef.current ? productRef.current?.name : '',
      color_palette_1: hexToRgb(
        businessData.brandingInfos?.data[0]?.primaryColor
      ),

      color_palette_2: hexToRgb(
        businessData.brandingInfos?.data[0]?.secondaryColor
      ),

      complexidade: -3,
      masculino: 5,
      extravagante: -5,
      moderno: 5,
      distante: 0,
      serio: 0,
      industrial: 0,
      elitizado: 0,
      sofisticado: 0,
      refinado: 0,

      macro_effects: false,
      num_imgs: [1],

      has_logo: false, //!Quando não haver logo passar como false e não enviar o logo_base64
      // logo_base64: '',

      template: false,
    }

    if (imageGaleryArray?.length !== 0 && imageGaleryArray) {
      imageGaleryArray.forEach((image2) => {
        if (!bodyGenerateArt?.img_base64) {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [[image2.fileDataBase64.split(',')[1]]],
          }
        } else {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [
              ...bodyGenerateArt.img_base64,
              [image2.fileDataBase64.split(',')[1]],
            ],
          }
        }
      })
    }

    if (imageAIArray.length !== 0 && imageAIArray) {
      imageAIArray.forEach((image2) => {
        if (!bodyGenerateArt?.img_base64) {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [[image2.image.split(',')[1]]],
          }
        } else {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [
              ...bodyGenerateArt.img_base64,
              [image2.image.split(',')[1]],
            ],
          }
        }
      })
    }

    if (imageUploadArray.length !== 0 && imageUploadArray) {
      imageUploadArray.forEach((image2) => {
        if (!bodyGenerateArt?.img_base64) {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [[image2.image.split(',')[1]]],
          }
        } else {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [
              ...bodyGenerateArt.img_base64,
              [image2.image.split(',')[1]],
            ],
          }
        }
      })
    }

    if (imageProductsArray.length !== 0 && imageProductsArray) {
      imageProductsArray.forEach((image2) => {
        if (!bodyGenerateArt?.img_base64) {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [[image2.fileDataBase64.split(',')[1]]],
            productIdImage: image2.id,
          }
        } else {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [
              ...bodyGenerateArt.img_base64,
              [image2.fileDataBase64.split(',')[1]],
            ],
            productIdImage: image2.id,
          }
        }
      })
    }

    const generateArtReturn = await PythonService.postGenerateArt(
      bodyGenerateArt
    )

    if (generateArtReturn?.data?.status === 200) {
      setIaData(generateArtReturn?.data?.data)
    }

    setLoading(false)
  }

  const handleOpenModal = (url, imageLayers) => {
    setImageUrl(url)
    setImageLayers(imageLayers)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      setStep(STEPS.STEP_SECOND_PASS_IMAGE)
    }
    if (navigation === 'next') {
      setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)
    }
  }

  return (
    <>
      <Loading enable={loading} />
      <div className={styles.container_header}>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>Nova</p>
          <p className={styles.title_new_posts_title}>Publicação</p>
        </div>
        <div className={styles.title_new_posts2}>
          <div className={styles.first_pass}>
            <div>
              <NumberOne width={'40px'} height={'40px'} />
            </div>
            <div className={styles.first_pass_title}>
              <p className={styles.first_pass_title_text}>1° Passo</p>
              <p className={styles.first_pass_subtitle}>Configurações</p>
            </div>
          </div>

          <div className={styles.second_pass}>
            <div className={styles.second_pass_box_number}>
              <NavigateArrowRigth />
              <NumberTwo width={'40px'} height={'40px'} />
            </div>
            <div>
              <p className={styles.first_pass_title_text}>2° Passo</p>
              <p className={styles.first_pass_subtitle}>Imagem</p>
            </div>
          </div>

          <div className={styles.third_pass}>
            <div className={styles.second_pass_box_number}>
              <NavigateArrowRigth />
              <NumberThree width={'40px'} height={'40px'} />
            </div>
            <div>
              <p className={styles.first_pass_title_text}>3° Passo</p>
              <p className={styles.first_pass_subtitle}>Publicação</p>
            </div>
          </div>

          <div className={styles.next_pass}>
            <NavigateArrowRigth />
          </div>
        </div>

        <div className={styles.container_content}>
          <div className={styles.container_filters}>
            <div className={styles.box_filter_title}>
              <InputIA
                placeholder={'Digite  aqui sua sugestão'}
                text={headlineText}
                setText={setHeadlineText}
                clickFunction={async () =>
                  await getIaHeadline(responseBusinesData)
                }
                setLoading={setLoading}
              />
              <div
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                  setLoading(true)
                  await getIaImages(responseBusinesData)
                  setLoading(false)
                }}
              >
                <Refresh width={'50px'} height={'50px'} />
              </div>
            </div>
            <div className={styles.box_filter_param}>
              <SelectRound
                className={styles.select_search}
                htmlFor="select"
                placeholder="Selecione"
                defaultSelected={{ label: 'Selecione um formato', value: '' }}
                options={[
                  { label: 'Teste', value: 1 },
                  { label: 'Teste2', value: 2 },
                ]}
              />
              <SelectRound
                className={styles.select_search}
                htmlFor="select"
                placeholder="Selecione"
                defaultSelected={{
                  label: t('fast_publishing_brand_information'),
                  value: '',
                }}
                options={[
                  { label: 'Teste', value: 1 },
                  { label: 'Teste2', value: 2 },
                ]}
              />
              <SelectRound
                className={styles.select_search}
                htmlFor="select"
                placeholder="Selecione"
                defaultSelected={{ label: 'Selecione um produto', value: '' }}
                options={productTypeOptions}
              />
              <SelectRound
                className={styles.select_search}
                htmlFor="select"
                placeholder="Selecione"
                defaultSelected={{
                  label: 'Selecione um público-alvo',
                  value: '',
                }}
                options={productTargetAudienceOptions}
              />
            </div>
          </div>

          <div className={styles.container_examples}>
            <div className={styles.container_examples_image}>
              {iaData?.map((item, index) => (
                <div
                  className={styles.box_example_image}
                  key={index}
                  onClick={() => {
                    handleOpenModal(
                      'data:image/png;base64,' + item?.srcComTratativa,
                      item
                    )
                  }}
                >
                  <img
                    src={'data:image/png;base64,' + item?.srcComTratativa}
                    alt=""
                    className={styles.example_image}
                  />
                </div>
              ))}

              <SimpleEditionModal
                isOpen={isModalOpen}
                setNextStep={() => setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                imageLayers={imageLayers}
                setImageLayers={setImageLayers}
                onClose={handleCloseModal}
                fontName={fontToRender}
                title={headlineText}
                subtitle={headlineSubtitle}
                publicTargetOptions={productTargetAudienceOptions}
                productOptions={productTypeOptions}
                onChangeColorPalleteSubtittle={(e) =>
                  setHeadlineSubtitle(e.target.value)
                }
                onChangeColorPalleteTittle={(e) =>
                  setHeadlineText(e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              nextButton('back')
            }}
          >
            <p className={styles.button_title}>Voltar</p>
          </button>

          {/* <button
            className={styles.button_next}
            onClick={() => {
              nextButton('next')
            }}
          >
            <p className={styles.button_title}>Proximo</p>
          </button> */}
        </div>
      </div>
    </>
  )
}

export default StepThirdPass

StepThirdPass.propTypes = {
  setStep: PropTypes.func,
  imageArray: PropTypes.array,
  setIaData: PropTypes.func,
  iaData: PropTypes.object,
}
