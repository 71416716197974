import styles from './styles.module.css'
import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

import { SubscriptionService } from '../../services'

import PlansPage from '../../pages/PlansPage'
import { motion } from 'framer-motion'

function WelcomePage() {
  const navigate = useNavigate()

  const { userPlan } = useAuth()

  const [loading, setLoading] = useState(false)
  const [showPlans, setShowPlans] = useState(false)

  useEffect(() => {
    loadDataUser()
  }, [showPlans])

  const loadDataUser = async () => {
    setLoading(true)

    const subscription = await SubscriptionService.getSubscriptionByUser()

    if (subscription.data.length > 0) {
      setShowPlans(false)
      navigate('/StepsPage', {
        state: { fastPublication: true },
      })
    } else {
      setShowPlans(true)
    }

    if (userPlan) {
      navigate('/HomePage')
    }

    setLoading(false)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        width: '100%',
        height: '100%',
        paddingTop: 20,
      }}
    >
      <div className={styles.page}>
        {/* <PlansCatalog setShowPlans={setShowPlans} /> */}
        <PlansPage welcomePage={true} setShowPlans={setShowPlans} />
      </div>
    </motion.div>
  )
}

export default WelcomePage
