import { useState } from 'react'
import { BoxChecket, BoxNotChecket } from '../../../assets/svgs/icons'
import InputMask from 'react-input-mask'
import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import { motion } from 'framer-motion'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_PASS_PASSWORD: 'stepPassword',
  STEP_PASS_ADDRESS: 'stepAddress',
}
function StepCPF({
  setCpf,
  cpf,
  setNumberStep,
  setStep,
  setCorporateReason,
  corporateReason,
  setCnpj,
  cnpj,
  setFantasyName,
  fantasyName,
}) {
  const [exibe, setExibe] = useState(false)
  
  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nome e Sobrenome',
      current: true
    },
    {
      pass: '2º Passo',
      title: 'Email',
      current: true
    },
    {
      pass: '3º Passo',
      title: 'Telefone',
      current: true
    },
    {
      pass: '4º Passo',
      title: 'Senha',
      current: true
    },
    {
      pass: '5º Passo',
      title: 'CPF',
      current: true
    },
    {
      pass: '6º Passo',
      title: 'Endereço',
      current: false
    },
  ];

  async function isValidCNPJ(value) {
    
    const numericValue = value.replace(/[^\d]+/g, '')
    

    if (numericValue.length === 14) {
      // Verifica se é um CNPJ
      const invalidCpns = [
        '00000000000000',
        '11111111111111',
        '22222222222222',
        '33333333333333',
        '44444444444444',
        '55555555555555',
        '66666666666666',
        '77777777777777',
        '88888888888888',
        '99999999999999',
      ]

      if (invalidCpns.includes(numericValue)) {
        
        return false
      }

      let size = numericValue.length - 2
      let numbers = numericValue.substring(0, size)
      const digits = numericValue.substring(size)
      let sum = 0
      let pos = size - 7

      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--
        if (pos < 2) pos = 9
      }

      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(0), 10)) {
        return false
      }

      size = size + 1
      numbers = numericValue.substring(0, size)
      sum = 0
      pos = size - 7

      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--
        if (pos < 2) pos = 9
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(1), 10)) {
        return false
      }
      
      return true // É um CNPJ válido
    } else {
      
      return false
    }
  }

  async function isValidCPF(value) {
    
    const numericValue = value.replace(/[^\d]+/g, '')

    

    if (numericValue.length === 11) {
      // Verifica se é um CPF
      const invalidCpfs = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ]

      if (invalidCpfs.includes(numericValue)) {
        
        return false
      }

      

      let sum = 0
      let mod = 0

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(numericValue.substring(i - 1, i), 10) * (11 - i)
      }

      mod = (sum * 10) % 11

      if (mod === 10 || mod === 11) {
        
        mod = 0
      }

      if (mod !== parseInt(numericValue.substring(9, 10), 10)) {
        
        return false
      }

      sum = 0

      for (let i = 1; i <= 10; i++) {
        sum += parseInt(numericValue.substring(i - 1, i), 10) * (12 - i)
      }

      mod = (sum * 10) % 11

      if (mod === 10 || mod === 11) {
        mod = 0
      }

      if (mod !== parseInt(numericValue.substring(10, 11), 10)) {
        return false
      }
      
      return true // É um CPF válido
    } else {
      return false // Não é nem CPF nem CNPJ
    }
  }

  const handleNavigate = async (type) => {
    const isValidCpf = await isValidCPF(cpf)
    const isValidCnpj = await isValidCNPJ(cnpj)
    
    if (type === 'next') {
      if (isValidCpf === false) {
        notifyErrorCustom(
          t('fast_publishing_cpf_invalid_alert')
        )
        return false
      }
      if (isValidCnpj === false && cnpj != '') {
        notifyErrorCustom(
          t('fast_publishing_cnpj_invalid_alert')
        )
        return false
      }
      ~setStep(STEPS.STEP_PASS_ADDRESS)
    } else {
      setStep(STEPS.STEP_PASS_PASSWORD)
    }
  }
  const {t} = useTranslation();

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        {/* <PreviousPass steps={stepsArray}/> */}
        <div className={styles.box_step}>
          <div className={styles.box_page}>
            <div className={styles.box_content}>
              <p style={{ margin: '0px' }}>{t("fast_publishing_register_title")}</p>
              <h1 style={{ marginTop: '0px' }}>{t("fast_publishing_create_account")}</h1>

              <div className={styles.box_input_name}>
                <p>{t("fast_publishing_cpf")}</p>

                <InputMask
                  className={styles.input_name}
                  type="text"
                  value={cpf}
                  mask="999.999.999-99"
                  placeholder="CPF"
                  onChange={(e) => setCpf(e.target.value)}
                />
              </div>
              <div className={styles.check_cnpj}>
                <div
                  className={styles.box_check_cnpj}
                  onClick={() => setExibe(!exibe)}
                >
                  {exibe ? (
                    <BoxChecket />
                  ) : (
                    <BoxNotChecket borderColor={'#000'} />
                  )}
                </div>
                <p>{t("fast_publishing_cpf_profile_for_the_company")}</p>
              </div>
              {exibe && (
                <div className={styles.box_fantasy_name}>
                  <p>{t("fast_publishing_cnpj")}</p>
                  <InputMask
                    className={styles.input_name}
                    type="text"
                    mask="99.999.999/9999-99"
                    placeholder="CNPJ"
                    value={cnpj}
                    onChange={(event) => setCnpj(event.target.value)}
                  />
                  <p>{t("fast_publishing_cpf_corporate_name")}</p>
                  <input
                    className={styles.input_name}
                    value={corporateReason}
                    onChange={(event) => setCorporateReason(event.target.value)}
                  />
                  <p>{t("fast_publishing_cpf_trade_name")}</p>
                  <input
                    className={styles.input_name}
                    value={fantasyName}
                    onChange={(event) => setFantasyName(event.target.value)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.box_button}>
            <button
              className={styles.back_button}
              onClick={() => handleNavigate('back')}
            >
              {t("back_buttom")}
            </button>
            <button
              className={styles.next_button}
              onClick={() => handleNavigate('next')}
            >
              {t("next_buttom")}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default StepCPF
