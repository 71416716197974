import { useEffect, useState } from 'react'
import { ButtonFavoriteStarsPress, PasteIcon } from '../../../assets/svgs/icons'
import styles from './styles.module.css'

import { notifySuccessCustom } from '../../../utils/notify'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useAuth } from '../../../hooks/useAuth'
import { UserAccountsService } from '../../../services'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_PASS_PUBLICATION: 'stepPublication',
  STEP_PASS_PUBLICATION_DOWNLOAD: 'stepPublicationDownload',
}

function StepPublicationDownload({
  setStep,
  postSelected,
  setQuickRegistration,
  quickRegistration,
}) {
  const navigate = useNavigate()

  const stars = [...new Array(5).keys()]
  const [activeIndex, setActiveIndex] = useState()
  const [visibleTextCopy, setVisibleTextCopy] = useState(false)

  const { setFirstLogin } = useAuth()

  const { t } = useTranslation()

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true,
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true,
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: true,
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: true,
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: true,
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: true,
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: true,
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: true,
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: true,
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: true,
    },
  ]

  //  const { setQuickRegistration, quickRegistration } = useAuth()

  const onClickStar = (index) => {
    setActiveIndex((oldState) => (oldState == index ? undefined : index))
  }

  useEffect(() => {
    loadFirstLogin()
  }, [])

  const loadFirstLogin = async () => {
    const { data: dataFirstLogin, responseStatus } =
      await UserAccountsService.getUserAccountsByLogin()

    //setFirstLogin(dataFirstLogin)

    updateFirstLogin(dataFirstLogin)
  }

  const updateFirstLogin = async (dataFirstLogin) => {
    const { ...rest } = dataFirstLogin

    delete rest.uuid
    delete rest.createdAt
    delete rest.updatedAt
    delete rest.deletedAt
    delete rest.PaymentHistory

    rest.firstLogin = false
    rest.photoName = ''
    rest.photoUrl = ''

    const { data, responseStatus } =
      await UserAccountsService.putUserAccountsByLogin(rest)

    //setFirstLogin(false)
    // await NotificationService.postNotification({
    //   appointmentNotification: true,
    //   passwordReset: true,
    //   notifyUpdate: true,
    //   PublishedAppointmentNotifications: true,
    //   NewsSuggestionsUpdates: true,
    // })
  }

  const handleNavigate = () => {
    // setFirstLogin(false)
    navigate('/WelcomePage', {
      state: { fastPublication: true },
    })
    // navigate('/StepsPage', {
    //   state: { fastPublication: true, },
    // })
  }

  function downloadBase64Image(base64Data, fileName) {
    // Cria um link (elemento <a>)
    const link = document.createElement('a')

    // Define o atributo "href" com os dados em base64
    link.href = 'data:image/png;base64,' + base64Data

    // Define o nome do arquivo para download
    link.download = fileName

    // Adiciona o link ao DOM (não será visível)
    document.body.appendChild(link)

    // Simula o clique no link para iniciar o download
    link.click()

    // Remove o link do DOM após o clique
    document.body.removeChild(link)
  }

  const textCopy = (textcopy) => {
    navigator.clipboard
      .writeText(textcopy)
      .then(() => {
        // Mostrar mensagem
        setVisibleTextCopy(true)

        notifySuccessCustom('Copiado')

        // Esconder mensagem após 2 segundos
        // setTimeout(() => {
        //   setVisibleTextCopy(false)
        // }, 1000)
      })
      .catch((err) => {
        console.error('Erro ao copiar texto: ', err)
      })
  }
  return (
    <>
      <motion.div
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '-100vw' }}
        transition={{ type: 'tween', duration: 0.5 }}
        style={{
          width: '100%',
          // height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.box_step}>
          {/* <PreviousPass steps={stepsArray}/> */}
          <div className={styles.box_page}>
            <p style={{ margin: '0px' }}>
              {t('fast_publishing_post_download_text')}
            </p>
            <h1 style={{ marginTop: '0px', fontSize: '35px', fontWeight: 700 }}>
              {t('fast_publishing_post_download')}
            </h1>

            <div className={styles.container_publication}>
              <div className={styles.box_publication}>
                <img
                  className={styles.image_publication}
                  src={'data:image/png;base64,' + postSelected?.base64}
                  alt="teste"
                />
              </div>

              <div className={styles.box_star_index}>
                {stars.map((index) => (
                  <ButtonFavoriteStarsPress
                    key={`star_${index}`}
                    color={'#000'}
                    isActive={index <= activeIndex}
                    onClick={() => onClickStar(index)}
                  />
                ))}
              </div>

              <div className={styles.box_download_button}>
                <button
                  className={styles.download_button}
                  onClick={() =>
                    downloadBase64Image(
                      postSelected?.base64,
                      'minha-imagem.png'
                    )
                  }
                >
                  {t('fast_publishing_post_download_button')}
                </button>
              </div>

              {/* {visibleTextCopy && (
            <div style={{ height: 0 }}>
            <p>Copiado!</p>
            </div>
            )} */}
              {visibleTextCopy ? (
                <div className={styles.box_legend_publication}>
                  <div className={styles.legend_publication_copy}>
                    <div
                      className={styles.box_icon}
                      onClick={() => textCopy(postSelected?.legend)}
                    >
                      <PasteIcon color="#3CBC00" width={30} height={30} />
                    </div>

                    <div className={styles.divider_copy}></div>

                    <p className={styles.legend_text_copy}>
                      {t('fast_publishing_post_download_button_copy')}
                    </p>
                  </div>
                </div>
              ) : (
                <div className={styles.box_legend_publication}>
                  <div className={styles.legend_publication}>
                    <div
                      className={styles.box_icon}
                      onClick={() => textCopy(postSelected?.legend)}
                    >
                      <PasteIcon color="black" width={30} height={30} />
                    </div>

                    <div className={styles.divider}></div>

                    <p className={styles.legend_text}>
                      {t('fast_publishing_post_download_button_after_copy')}
                    </p>
                  </div>
                </div>
              )}

              {/* <div className={styles.box_legend_publication}>
                <div className={styles.legend_publication}>
                  <div
                    className={styles.box_icon}
                    onClick={() => textCopy(postSelected?.legend)}
                  >
                    
                    <PasteIcon color="black" width={30} height={30} />
                  </div>

                  <div className={styles.divider}></div>

                  <p className={styles.legend_text}>
                    {visibleTextCopy
                      ? t('fast_publishing_post_download_button_copy')
                      : 'click para copiar a legenda'}
                    {}
                  </p>
                </div>
              </div> */}
            </div>
          </div>
          <div className={styles.box_button}>
            {/* <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
          >
          Voltar
          </button> */}
            <button
              className={styles.next_button}
              onClick={() => handleNavigate()}
            >
              {t('next_buttom')}
            </button>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default StepPublicationDownload
