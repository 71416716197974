import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import {
  CloundArrowUp,
  Interrogation,
  Stars,
  Trash,
} from '../../../assets/svgs/icons'
import { PythonService } from '../../../services'
import { notifyErrorCustom } from '../../../utils/notify'
import { motion } from 'framer-motion'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'

const STEPS = {
  STEP_PASS_PUBLICATION: 'stepPublication',

  STEP_PASS_LOGO: 'stepLogo',
}
function StepImage({ setStep, filesSelectedArray, setFilesSelectedArray }) {
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const storage = window.localStorage

  // const handleRemoveBackground = async (index, base64) => {
  //   const { data } = await PythonService.postRemoveBackground({ image: base64 })
  //   const noBgImage = data.data.images

  //   filesSelectedArray[index].fileDataBase64 = noBgImage[1]?.image
  //   filesSelectedArray[index].isOriginal = noBgImage[1]?.isOriginal

  //   setFilesSelectedArray([...filesSelectedArray])
  // }

  useEffect(() => {
    loadDropdownsImageCategory()
  }, [])

  const { t } = useTranslation()
  const [imageCategoriesOptions, setImageCategoriesOptions] = useState([
    { label: '', value: '' },
  ])

  const loadDropdownsImageCategory = async () => {
    // faça chamada dos serviços
    // const { data: categories, responseStatus: categoriesStatus } =
    //   await getAllImageCategories()

    //altere estados
    // if (categoriesStatus === 200)
    //   setImageCategoriesOptions(
    //     categories.map((e) => ({ label: e.name, value: e.id }))
    //   )
    const dataCategories =
      storage.i18nextLng == 'en'
        ? [
            { label: 'Main', value: 'Principal' },
            { label: 'complementary', value: 'Complementar' },
            { label: 'Background', value: 'Background' },
          ]
        : [
            { label: 'Principal', value: 'Principal' },
            { label: 'Complementar', value: 'Complementar' },
            { label: 'Background', value: 'Background' },
          ]

    setImageCategoriesOptions(dataCategories)
  }

  const handleAddCategoryImageBank = (idCategory) => {
    let image = filesSelectedArray[0]
    image.categoryId = idCategory
  }

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true,
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true,
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: true,
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: true,
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: true,
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: true,
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: true,
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: true,
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: false,
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false,
    },
  ]

  const handleRemoveBackground = async (index, base64) => {
    setIsLoading(true) // Indica que o processo começou
    setProgress(10) // Progresso inicial

    try {
      // Faz a chamada ao serviço Python
      const { data } = await PythonService.postRemoveBackground({
        image: base64,
      })
      setProgress(50) // Atualiza o progresso após a chamada

      const noBgImage = data.data.images

      // Atualiza os dados da imagem
      filesSelectedArray[index].fileDataBase64 = noBgImage[1]?.image
      filesSelectedArray[index].isOriginal = noBgImage[1]?.isOriginal
      setProgress(80) // Progresso ao finalizar a atualização da imagem

      setFilesSelectedArray([...filesSelectedArray]) // Atualiza o estado

      setProgress(100) // Progresso final
    } catch (error) {
      console.error(t('fast_publishing_error_removing_background'), error)
    } finally {
      setTimeout(() => {
        setIsLoading(false) // Oculta a barra de progresso
        setProgress(0) // Reseta o progresso
      }, 500) // Pequeno delay para exibir a barra de progresso finalizada
    }
  }

  const onDragOver = (event) => {
    event.preventDefault()
    // setDropzoneHover(true)
  }

  const onDragLeave = () => {
    // setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setFilesSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
        }

        setFilesSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  // function formatBytes(bytes, decimals = 0) {
  //   if (bytes === 0) return '0 Bytes'

  //   const k = 1024
  //   const dm = decimals < 0 ? 0 : decimals
  //   const sizes = ['Bytes', 'KB', 'MB']

  //   const i = Math.floor(Math.log(bytes) / Math.log(k))

  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  // }

  function formatBytesToMB(bytes, decimals = 2) {
    if (bytes === 0) return '0 MB'

    const k = 1024
    return parseFloat((bytes / Math.pow(k, 2)).toFixed(decimals)) + ' MB'
  }

  const handleRemoveFile = (index) => {
    const updatedFilesArray = filesSelectedArray.filter((_, i) => i !== index)

    setFilesSelectedArray(updatedFilesArray)
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (filesSelectedArray.length === 0) {
        notifyErrorCustom(
          t('fast_publishing_choose_an_image_for_art_generation')
        )
        return
      }
      // else {
      // }
      setStep(STEPS.STEP_PASS_PUBLICATION)
    } else {
      setStep(STEPS.STEP_PASS_LOGO)
    }
  }
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        {/* <PreviousPass steps={stepsArray}/> */}

        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>{t('fast_publishing_image_text')}</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
            {t('fast_publishing_image')}
          </h1>

          <div className={styles.container_modal}>
            <div className={styles.content_up_image}>
              <div className={styles.modal_container_drag}>
                <div className={styles.modal_drag}>
                  {/* <label htmlFor="arquivo">
                <h3>Arrase & Solte o arquivo</h3>
                <p>Ou selecione o arquivo no explorador</p>
                </label> */}

                  <div
                    className={styles.modal_drag_drop}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    // onClick={handleShowListImages}

                    // style={{
                    //   height: '100px',
                    //   width: '100%',
                    //   border: '2px dashed black',
                    //   backgroundColor: dropzoneHover ? 'blue' : 'red',
                    // }}
                    // className={styles.modal_drag}
                  >
                    <label htmlFor="arquivo">
                      <CloundArrowUp color={'#B900FE'} />
                      <h2
                        className={
                          filesSelectedArray.length !== 0
                            ? styles.modal_drag_drop_title_short
                            : styles.modal_drag_drop_title
                        }
                      >
                        {t('fast_publishing_drag_and_drop')}
                      </h2>
                      <p
                        className={
                          filesSelectedArray.length !== 0
                            ? styles.modal_drag_drop_sub_title_short
                            : styles.modal_drag_drop_sub_title
                        }
                      >
                        {t('fast_publishing_drag_and_drop_subtitle')}
                      </p>
                    </label>
                    <input
                      type="file"
                      name="arquivo"
                      id="arquivo"
                      onChange={(e) => onFileChange(e)}
                    ></input>
                    {/* {fileData && (
                      <img
                      style={{ width: 50, height: 50 }}
                      src={fileData}
                      alt="file-preview"
                      />
                      )} */}
                  </div>
                </div>
              </div>

              {filesSelectedArray.length !== 0 && (
                <div className={styles.content_list_image}>
                  <div className={styles.container_box_list_image}>
                    {filesSelectedArray?.map((image, index) => (
                      <div
                        key={index}
                        className={styles.container_list_image}
                        // style={{ border: '1px solid red' }}
                      >
                        <div className={styles.item_list_image}>
                          <img
                            src={image.fileDataBase64}
                            alt="file-preview"
                            style={{
                              width: 40,
                              height: 40,
                              objectFit: 'cover',
                            }}
                          />
                          <div className={styles.box_data_info}>
                            <p className={styles.box_data_info_title}>
                              {image.name}
                            </p>
                            <p className={styles.box_data_info_size}>
                              {t('fast_publishing_logo_size_image')} &nbsp;
                              <b> {formatBytesToMB(image.size)} </b>
                            </p>
                          </div>
                        </div>
                        <div className={styles.item_list_image_buttons}>
                          <button
                            className={styles.button_cancel}
                            onClick={() => handleRemoveFile(index)}
                          >
                            <p className={styles.button_cancel_title}>
                              {t('fast_publishing_logo_size_delete_image')}
                            </p>
                            <Trash />
                          </button>
                          <button
                            className={styles.button_remove_background}
                            onClick={() =>
                              handleRemoveBackground(
                                index,
                                image.fileDataBase64
                              )
                            }
                          >
                            <p
                              className={styles.button_remove_background_title}
                            >
                              {t(
                                'fast_publishing_logo_remove_background_image'
                              )}
                            </p>{' '}
                            <Stars />
                          </button>
                          {/* <div
                          // style={{
                          //   pointerEvents: generateArtIa ? 'none' : 'auto',
                          // }}
                          >
                            <SelectSquare
                              // backgroundColor={
                              //   generateArtIa ? '#dcdcdc' : '#6a0098'
                              // }
                              backgroundColor="#6a0098"
                              // arrowColor={generateArtIa ? 'black' : '#fff'}
                              arrowColor="#fff"
                              border={true}
                              borderColor={'#dadada'}
                              placeholder={t(
                                'stepSecondPass.title.imageCategory'
                              )}
                              arrowDirection="down"
                              options={imageCategoriesOptions}
                              fontSize={12}
                              padding="7.2px 14.4px"
                              radius="4px"
                              minWidth="190px"
                              // color={generateArtIa ? 'gray' : '#fff'}
                              color={'#fff'}
                              // defaultSelected={
                              //   generateArtIa
                              //     ? imageCategoriesOptions.find(
                              //         (e) => e.value === 'Background'
                              //       )
                              //     : null
                              // }
                              onClickItem={({ value }) =>
                                 handleAddCategoryImageBank(value)
                             // console.log('value',value)
                              
                              }
                            />
                          </div> */}
                        </div>
                      </div>
                    ))}
                  </div>
                  {isLoading && (
                    <div className={styles.progressBarContainer}>
                      <div
                        className={styles.progressBar}
                        style={{ width: `${progress}%` }}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className={styles.modal_button_save}>
                <button className={styles.button_add}>
                  <label className={styles.button_add_label} htmlFor="arquivo">
                    {t('fast_publishing_logo_add_image')}
                  </label>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.box_button}>
          {/* <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            {t('back_buttom')}
          </button> */}
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            {t('next_buttom')}
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepImage
