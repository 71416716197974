import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import Logo from '../../../assets/logos/postei-logo-color.png'
import { motion } from 'framer-motion'
import { UserService } from '../../../services'

import posteiLogoColor from '../../../assets/logos/postei-logo-color.png'
import posteiLogoTextColor from '../../../assets/logos/postei-logo-text-color.png'
import { useAuth } from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
function StepBusinessInfo({ setSubject, subject, setStep }) {
  const { t } = useTranslation()

  const { logout } = useAuth()

  const STEPS = {
    STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
  }

  const handleNavigate = async (type) => {
    console.log('entrou')
    if (type === 'next') {
      setStep(STEPS.STEP_PASS_NICHE_AND_OBJECTIVE)
    }
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.page}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: 26,
              color: '#190027',
              fontWeight: 500,
            }}
          >
            {/* {`Boas Vindas, ${userData.userName}!`} */}
          </p>

          {/* <img src={welcomeIcon} alt="welcomeIcon" style={{ height: 260 }} /> */}

          <p
            style={{
              textAlign: 'center',
              fontSize: 45,
              width: 610,
              fontWeight:'400',
              color: '#190027',
            }}
          >
           {t("fast_publishing_step_business_info.title")} <b style={{color:'#0FBE00'}}>{t("fast_publishing_step_business_info.business")}</b>
          </p>

          <p
            style={{
              textAlign: 'center',
              fontSize: 22,
              width: 610,
              color: '#190027',
              margin: 0,
            }}
          >
            {t("fast_publishing_step_business_info.description")}
          </p>

          <p
            style={{
              textAlign: 'center',
              fontSize: 18,
              width: 610,
              color: '#190027',
              margin: 20,
            }}
          >
            {t("fast_publishing_step_business_info.description_2")}
          </p>

          <button
            style={{
              margin: 4,
              padding: '10px 20px',
              borderRadius: 4,
              backgroundColor: '#6A0098',
              fontSize: 12,
              width: 180,
            }}
            onClick={() => handleNavigate('next')}
          >
            {t("fast_publishing_step_business_info.button_start_settings")}
          </button>
          <button
            style={{
              margin: 4,
              padding: '10px 20px',
              borderRadius: 4,
              backgroundColor: '#ffff',
              fontSize: 12,
              color: '#6A0098',
              border: '1px solid #6A0098',
              // width: 80,
            }}
             onClick={handleLogout}
          >
            {t("fast_publishing_step_business_info.button_logout")}
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepBusinessInfo
