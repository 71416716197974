/* eslint-disable react/prop-types */
import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'
import {
  Accept,
  // CircleX,
  Cross,
  NumberOne,
  NumberThree,
  NumberTwo,
  Pen2,
  // Refresh,
  WhiteRefresh,
} from '../../../assets/svgs/icons'
import { useEffect, useState } from 'react'
import { SelectRound } from '../../../components/Select/SelectRound'
import { motion } from 'framer-motion'
import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'
import {
  BusinessService,
  ProductService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services'
import { getLegendsService } from '../../../services/postsServices/getLegendsService'
import Loading from '../../../components/Loading'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_THIRD_PASS_PUBLICATION: 'stepPublication',
  STEP_FIFTH_PASS_SCHEDULE: 'stepSchedule',
}

function StepFourtPass({
  setStep,
  imageArray,
  selectedArrayImage,
  headlineText,
  legend,
  legendList,
  setLegendList,
  setLegend,
  campaignRef,
  headlineList,
}) {
  // const navigation = useNavigate()
  const [loading, setLoading] = useState(false)

  const [stateLegend, setStateLegend] = useState([])

  const { clientData } = useClient()

  const { t } = useTranslation()

  if (stateLegend.length === 0) {
    legendList.map((i) => {
      stateLegend.push(true)
    })
  }

  useEffect(() => {
    // legendGenerate()
  }, [])

  const deleteLegend = async (index) => {
    const legendListCopy = { ...legendList }

    if (index in legendListCopy) {
      delete legendListCopy[index]
      setLegendList(legendListCopy)
    } else console.log('Índice não encontrado no objeto.')

    setLegendList(legendListCopy)
    const copyState = { ...stateLegend }
    copyState[index] = false
    setStateLegend(copyState)
  }

  const confirmLegend = async (index) => {
    const copyState = { ...stateLegend }
    copyState[index] = false
    setStateLegend(copyState)
  }

  const legendGenerate = async (index) => {
    setLoading(true)

    let businessId
    let descriptionBusines

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)

      businessId = data[0]?.uuid
      descriptionBusines = data[0]?.description
    } else {
      const { data } = await BusinessService.getBusinessByLogin()

      businessId = data[0]?.uuid
      descriptionBusines = data[0]?.description
    }

    const productInfos = await ProductService.getProductById(
      campaignRef?.current?.productId
    )

    const voiceToneInfos = await VoiceToneService.getVoiceToneByBusiness(
      businessId
    )
    // const businessInfos = await BusinessService.getBusinessByLogin()
    const targetAudiencesInfos =
      await TargetAudienceService.getAllTargetAudienceById(
        campaignRef?.current?.targetAudienceId
      )

    const bodyLegend = {
      headlineSelected: headlineText,
      publicationPurpose: campaignRef?.current?.objective,
      descriptionBusiness: descriptionBusines,
      voiceTone: voiceToneInfos?.data[0]?.tone,
      socialClass: targetAudiencesInfos?.data[0]?.educationLevelValue,
      gender: targetAudiencesInfos?.data[0]?.genderValue,
      profession: targetAudiencesInfos?.data[0]?.profession,
      nameProduct: productInfos?.data?.name,
      productType: productInfos?.data?.productTypeValue,
      productDescription: productInfos?.data?.description,
      value: productInfos?.data?.value,
      promotionalValue: productInfos?.data?.promotionalValue,
    }

    const data = await getLegendsService(bodyLegend)

    setLegend(data.message)
    legendList[index] = data.message

    setLoading(false)
  }

  const [activeImputLegend, setActiveImputLegend] = useState(true)
  const [activeInputs, setActiveInputs] = useState(
    Array(selectedArrayImage.length).fill(false)
  )

  const [legendValue, setLegendValue] = useState(legendList)

  const legendUpdate = (index) => {
    setActiveInputs((prevActiveInputs) => {
      const newActiveInputs = [...prevActiveInputs]
      newActiveInputs[index] = !newActiveInputs[index]
      return newActiveInputs
    })
  }

  const handleLegendChange = (e, index) => {
    const updatedLegendValue = [...legendValue] // Faz uma cópia do array original
    updatedLegendValue[index] = e.target.value // Atualiza o valor no índice especificado
    setLegendValue(updatedLegendValue) // Define o novo array como o estado
    setLegendList(updatedLegendValue)
  }

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      setStep(STEPS.STEP_THIRD_PASS_PUBLICATION)
    }
    if (navigation === 'next') {
      setStep(STEPS.STEP_FIFTH_PASS_SCHEDULE)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container_header}
    >
      <Loading enable={loading} />
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Nova</p>
        <p className={styles.title_new_posts_title}>Publicação</p>
      </div>
      <div className={styles.title_steps}>
        <div className={styles.first_pass}>
          <div>
            <NumberOne width={'40px'} height={'40px'} />
          </div>
          <div className={styles.first_pass_title}>
            <p className={styles.first_pass_title_text}>1° Passo</p>
            <p className={styles.first_pass_subtitle}>Configurações</p>
          </div>
        </div>

        <div className={styles.second_pass}>
          <div>
            <NumberTwo width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>2° Passo</p>
            <p className={styles.first_pass_subtitle}>Imagem</p>
          </div>
        </div>

        <div className={styles.third_pass}>
          <div>
            <NumberThree width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>3° Passo</p>
            <p className={styles.first_pass_subtitle}>Legendas</p>
          </div>
        </div>
      </div>

      <div className={styles.container_content}>
        {selectedArrayImage.map((item, index) => (
          <div key={index} className={styles.card_subtitle}>
            <div className={styles.box_image_publication}>
              <img src={item} className={styles.image_publication} />
            </div>
            <div className={styles.box_title_publication}>
              {/* <p className={styles.title_publication}>{headlineText}</p> */}
              <p className={styles.title_publication}>{headlineList[index]}</p>
            </div>

            {stateLegend[index] && (
              <>
                <div>
                  <SelectRound
                    className={styles.select_search}
                    htmlFor="select"
                    placeholder="Selecione"
                    defaultSelected={{
                      label: t('fast_publishing_brand_information'),
                      value: '',
                    }}
                    options={[
                      { label: 'Teste', value: 1 },
                      { label: 'Teste2', value: 2 },
                    ]}
                    variant={'right'}
                  />
                </div>
                <div className={styles.box_buttons}>
                  <div
                    className={styles.button_subtitle_remove}
                    onClick={() => deleteLegend(index)}
                  >
                    <Cross color={'#E40000'} width={'14px'} height={'14px'} />
                  </div>
                  <div
                    className={styles.button_subtitle_refresh}
                    onClick={() => legendUpdate(index)}
                  >
                    <Pen2 color="#000" width={'24px'} height={'24px'} />
                  </div>
                  <div
                    className={styles.button_subtitle_refresh}
                    onClick={() => legendGenerate(index)}
                  >
                    <WhiteRefresh width={'24px'} height={'24px'} />
                  </div>
                  <div
                    className={styles.button_subtitle_accept}
                    onClick={() => confirmLegend(index)}
                  >
                    <Accept color={'#0FBE00'} width={'16px'} height={'16px'} />
                  </div>
                </div>
              </>
            )}

            <div className={styles.box_description_publication}>
              {/* <p className={styles.description_publication}>{legend}</p> */}

              <textarea
                className={
                  !activeInputs[index]
                    ? styles.input_legend_readOnly
                    : styles.input_legend
                }
                readOnly={!activeInputs[index]}
                name=""
                id=""
                cols="30"
                rows="10"
                onChange={(e) => handleLegendChange(e, index)}
              >
                {legendList[index]}
              </textarea>

              {/* <p className={styles.description_publication}>
                {legendList[index]}
              </p> */}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.button_nav}>
        <button
          className={styles.button_back}
          onClick={() => {
            nextButton('back')
          }}
        >
          <p className={styles.button_title}>Voltar</p>
        </button>

        <button
          className={styles.button_next}
          onClick={() => {
            nextButton('next')
          }}
        >
          <p className={styles.button_title}>Prosseguir</p>
        </button>
      </div>
    </motion.div>
  )
}

export default StepFourtPass
