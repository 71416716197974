import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { UserGroup } from '../../../assets/svgs/icons'
import {
  getAllBusinessNiche,
  postBusinessNiche,
  putBusinessNiche,
} from '../../../services/businessNicheService'
import {
  postBusiness,
  getBusinessByLogin,
  putBusiness,
} from '../../../services/business'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'

import { motion } from 'framer-motion'
import { UserAccountsService } from '../../../services'
import { Navigate } from 'react-router-dom'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'

const storage = window.localStorage

const STEPS = {
  STEP_PASS_ADDRESS: 'stepAddress',
  //STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  STEP_PASS_BUSINESS_INFO: 'stepBusinessInfo',
  STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
}
function StepNIcheAndObjective({
  setStep,
  setBusinessAccountData,
  objectiveSelected,
  setObjectiveSelected,
}) {
  const { t } = useTranslation()
  const [businessNichesOptions, setBusinessNichesOptions] = useState([])
  const [nicheSelected, setNicheSelected] = useState('')
  const [subNicheSelected, setSubNicheSelected] = useState('')
  const [businessDescription, setBusinessDescriprion] = useState('')

  const [primeiraMetade, setPrimeiraMetade] = useState([])
  const [segundaMetade, setSegundaMetade] = useState([])

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true,
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: false,
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: false,
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: false,
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: false,
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: false,
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: false,
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: false,
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: false,
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false,
    },
  ]

  const objetiveOptions =
    storage.i18nextLng == 'en'
      ? [
          { label: 'Engagement', value: '01' },
          { label: 'Sales', value: '02' },
          { label: 'Range', value: '03' },
          { label: 'Launch', value: '04' }
        ]
      : [
          { label: 'Engajamento', value: '01' },
          { label: 'Vendas', value: '02' },
          { label: 'Alcance', value: '03' },
          { label: 'Lançamento', value: '04' }
        ]

  const businessList = [
    {
      value: 'Advocacia e Serviços Jurídicos',
      valueEn: 'Law and Legal Services',
    },
    {
      value: 'Agricultura e Agronegócio',
      valueEn: 'Agriculture and Agribusiness',
    },
    { value: 'Alimentos e Bebidas', valueEn: 'Food and Beverages' },
    { value: 'Alimentos Orgânicos', valueEn: 'Organic Food' },
    { value: 'Arte e Cultura', valueEn: 'Art and Culture' },
    { value: 'Automotivo', valueEn: 'Automotive' },
    { value: 'Barbearia', valueEn: 'Barbershop' },
    { value: 'Beleza e Salão', valueEn: 'Beauty and Salon' },
    { value: 'Cafeteria', valueEn: 'Cafeteria' },
    {
      value: 'Coaching e Desenvolvimento Pessoal',
      valueEn: 'Coaching and Personal Development',
    },
    { value: 'Educação', valueEn: 'Education' },
    { value: 'Entretenimento', valueEn: 'Entertainment' },
    { value: 'Esportes e Fitness', valueEn: 'Sports and Fitness' },
    {
      value: 'Imóveis e Construção Civil',
      valueEn: 'Real Estate and Construction',
    },
    { value: 'Indústria Farma', valueEn: 'Pharmaceutical Industry' },
    { value: 'Joias', valueEn: 'Jewelry' },
    { value: 'Jogos / Gaming', valueEn: 'Games / Gaming' },
    { value: 'Marketing e Publicidade', valueEn: 'Marketing and Advertising' },
    { value: 'Moda e Vestuário', valueEn: 'Fashion and Clothing' },
    { value: 'Móveis e Decoração', valueEn: 'Furniture and Decoration' },
    { value: 'Pet Care', valueEn: 'Pet Care' },
    { value: 'Saúde e Bem-estar', valueEn: 'Health and Well-being' },
    { value: 'Serviços Empresariais', valueEn: 'Business Services' },
    { value: 'Serviços Financeiros', valueEn: 'Financial Services' },
    { value: 'Serviços de RH', valueEn: 'HR Services' },
    {
      value: 'Sustentabilidade e Energia',
      valueEn: 'Sustainability and Energy',
    },
    { value: 'Tecnologia', valueEn: 'Technology' },
    { value: 'Viagem e Turismo', valueEn: 'Travel and Tourism' },
    { value: 'Designers', valueEn: 'Designers' },
    { value: 'Notícias e Jornalismo', valueEn: 'News and Journalism' },
    { value: 'Política', valueEn: 'Politics' },
  ]

  useEffect(() => {
    loadBusinessNiches()
  }, [])

  const loadBusinessNiches = async () => {
    const storage = window.localStorage
    let data = await getAllBusinessNiche()

    if (data.data.length <= 0) {
      for (const niche of businessList) {
        const bodyNiche = {
          name: niche.value,
          description: niche.value,
          descriptionEn: niche.valueEn,
        }
        await postBusinessNiche(bodyNiche)
      }
      const { data, responseStatus } = await getAllBusinessNiche()

      if (responseStatus === 200) {
        divideArray(
          data.map((e) => ({
            label: storage.i18nextLng == 'en' ? e.descriptionEn : e.description,
            value: e.uuid,
          }))
        )
      }
    } else {
      console.log('DataNicho2', data)
      if (!data.data[0].descriptionEn) {
        console.log('DataNicho3', data)
        let i = 0
        for (const niche of businessList) {
          const bodyNiche = {
            name: niche.value,
            description: niche.value,
            descriptionEn: niche.valueEn,
          }
          await putBusinessNiche(data.data[i].uuid, bodyNiche)
          i++
        }
        data = await getAllBusinessNiche()
      }

      if (data.responseStatus === 200) {
        divideArray(
          data.data.map((e) => ({
            label: storage.i18nextLng == 'en' ? e.descriptionEn : e.description,
            value: e.uuid,
          }))
        )
      }

      // if (responseStatus === 200) {
      //   divideArray(data.map((e) => ({ label: e.name, value: e.uuid })))

      //   // setBusinessNichesOptions(
      //   //   data.map((e) => ({ label: e.name, value: e.uuid }))
      //   // )

      //   console.log(
      //     'teste array2',
      //     data.map((e) => ({ label: e.name, value: e.uuid }))
      //   )
      // }
    }
  }

  const divideArray = async (array) => {
    // Ponto de divisão
    const splitIndex = Math.ceil(array.length / 2)

    // Dividir o array
    setPrimeiraMetade(array.slice(0, splitIndex))
    setSegundaMetade(array.slice(splitIndex))
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (objectiveSelected === '') {
        notifyErrorCustom(
          t('fast_publishing_choose_an_objective_for_your_post')
        )
        return
      }

      if (nicheSelected === '') {
        notifyErrorCustom(t('fast_publishing_choose_a_niche_for_your_post'))
        return
      }

      if (subNicheSelected === '') {
        notifyErrorCustom(t('fast_publishing_include_a_subniche_for_your_post'))
        return
      }

      createBusiness()

      setStep(STEPS.STEP_PASS_COLOR_PALETTE)
    } else {
      setStep(STEPS.STEP_PASS_BUSINESS_INFO)
    }
  }

  const createBusiness = async () => {
    let body = {
      businessNicheId: nicheSelected,
      // businessClientId: 'string',
      // campaignId: 'string',
      // guestId: 'string',
      subNiche: subNicheSelected,
      description: businessDescription,
    }

    const responseBusines = await getBusinessByLogin()

    let businessAccount

    if (responseBusines.data.length > 0) {
      businessAccount = await putBusiness(responseBusines?.data[0]?.uuid, body)

      // return
    } else {
      businessAccount = await postBusiness(body)
    }
    //return

    setBusinessAccountData(businessAccount)
  }
  // return ( <div>

  // </div>)
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        {/* <PreviousPass steps={stepsArray}/> */}

        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>
            {t('fast_publishing_brand_information')}
          </p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
            {t('fast_publishing_niche_and_objective')}
          </h1>

          <div className={styles.container_objective}>
            <p className={styles.module_title}>
              {t('fast_publishing_define_your_objective')}
            </p>
            <div className={styles.box_objective}>
              {objetiveOptions.map((objective, index) => (
                <div
                  className={
                    objectiveSelected.value === objective.value
                      ? styles.objective_selected
                      : styles.objective
                  }
                  key={index}
                  onClick={() => setObjectiveSelected(objective)}
                >
                  <div className={styles.box_icon}>
                    <UserGroup
                      color={
                        objectiveSelected.value === objective.value
                          ? '#fff'
                          : '#A7A7A7'
                      }
                    />
                  </div>

                  <p
                    className={
                      objectiveSelected.value === objective.value
                        ? styles.objective_text_selected
                        : styles.objective_text
                    }
                  >
                    {objective.label}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {objectiveSelected && (
            <div className={styles.container_niche}>
              <p className={styles.module_title}>
                {t('fast_publishing_now_choose_your_niche')}
              </p>

              <div className={styles.box_niche}>
                <div style={{ display: 'flex' }}>
                  {primeiraMetade.map((niche, index) => (
                    <div
                      className={
                        nicheSelected === niche.value
                          ? styles.niche_selected
                          : styles.niche
                      }
                      key={index}
                      onClick={() => setNicheSelected(niche?.value)}
                    >
                      <p className={styles.niche_label}>{niche.label}</p>
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {segundaMetade.map((niche2, index2) => (
                    <div
                      className={
                        nicheSelected === niche2.value
                          ? styles.niche_selected
                          : styles.niche
                      }
                      key={index2}
                      onClick={() => setNicheSelected(niche2?.value)}
                    >
                      <p className={styles.niche_label}>{niche2.label}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {objectiveSelected && nicheSelected && (
            <div className={styles.container_subniche}>
              <p className={styles.module_title}>
                {t('fast_publishing_enter_your_subniche')}
              </p>
              <div className={styles.box_input}>
                <input
                  className={styles.input_subniche}
                  type="text"
                  placeholder={t('fast_publishing_your_subniche')}
                  onChange={(e) => setSubNicheSelected(e.target.value)}
                />
              </div>
            </div>
          )}

          {objectiveSelected && nicheSelected && subNicheSelected && (
            <div className={styles.container_subniche}>
              <p className={styles.module_title}>
                {t('fast_publishing_enter_your_business_description')}
              </p>
              {/* <div className={styles.box_textarea}> */}
              <textarea
                className={styles.textarea_subniche}
                type="text"
                placeholder={t('fast_publishing_your_business_description')}
                onChange={(e) => setBusinessDescriprion(e.target.value)}
              />
              {/* </div> */}
            </div>
          )}
        </div>
        <div className={styles.box_button}>
          {/* <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            {t('fast_publishing_back')}
          </button> */}
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            {t('fast_publishing_next')}
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepNIcheAndObjective
