// /* eslint-disable react/prop-types */
// import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'

// import {
//   ProductService,
//   // TargetAudienceService,
//   CampaignService,
//   // BusinessService,
//   // BrandingService,
//   // PythonService,
// } from '../../../services/'

// import { useEffect, useState } from 'react'

// import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'

// import { NumberOne } from '../../../assets/svgs/icons'

// import { notifyErrorCustom } from '../../../utils/notify'

// const funnelOfSaleOptions = [
//   {
//     label: 'Topo',
//     value: 1,
//   },
//   {
//     label: 'Meio',
//     value: 2,
//   },
//   {
//     label: 'Fim',
//     value: 3,
//   },
// ]

// function StepFirstPass({
//   setStep,
//   clientData,
//   publicationDescriptionRef,
//   detailRef,
//   funelOfSaleRef,
//   campaignRef,
//   setSelectFunnel,
// }) {
//   const navigation = useNavigate()

//   const [campaignOptions, setCampaignOptions] = useState([])
//   const [campaignValue, setCampaignValue] = useState(undefined)

//   const [funnelOfSale, setFunnelOfSale] = useState(undefined)

//   const [detailsValue, setDetailsValue] = useState(undefined)
//   const [detailsOptions, setDetailsOptions] = useState([])

//   const [publicationDescription, setPublicationDescription] = useState('')

//   useEffect(() => {
//     loadProductServiceData()

//     loadDetailsData()
//     loadClientData()

//     if (funelOfSaleRef.current) {
//       setFunnelOfSale(funelOfSaleRef.current.value)
//     }

//     if (detailRef.current) {
//       setDetailsValue(detailRef.current)
//     }

//     if (publicationDescriptionRef.current) {
//       setPublicationDescription(publicationDescriptionRef.current)
//     }
//   }, [clientData])

//   const loadDetailsData = async () => {
//     setDetailsOptions([
//       {
//         value: 'Simples',
//         label: 'Simples',
//       },
//       {
//         value: 'Mediana',
//         label: 'Mediana',
//       },
//       {
//         value: 'Avançado',
//         label: 'Avançado',
//       },
//     ])
//   }

//   useEffect(() => {}, [])

//   const validateForm = () => {
//     if (
//       !campaignValue ||
//       !detailsValue ||
//       !funnelOfSale ||
//       !publicationDescription
//     ) {
//       notifyErrorCustom('Preencha os campos para prosseguir')
//       return false
//     }
//     return true
//   }

//   const loadProductServiceData = async () => {
//     if (!clientData?.uuid) {
//       const { data, responseStatus } =
//         await ProductService.getProductByNotBusinessClientList()

//       const nothingProduct = {
//         label: 'Nenhum',
//         value: null,
//       }

//       if (responseStatus === 200) {
//         const options = data.map((item) => ({
//           ...item,
//           price: item.value,
//           label: item.name,
//           value: item.uuid,
//         }))

//         const productTypeOptions = [nothingProduct, ...options]

//         setProductTypeOptions(productTypeOptions)
//       }
//     } else {
//       const { data, responseStatus } =
//         await ProductService.getProductByBusinessClientIdList(clientData?.uuid)
//       if (responseStatus === 200)
//         setProductTypeOptions(
//           data.map((item) => ({
//             ...item,
//             price: item.value,
//             label: item.name,
//             value: item.uuid,
//           }))
//         )
//     }
//   }

//   const loadClientData = async () => {
//     if (clientData.uuid) {
//       const { data: campaignData, responseStatus } =
//         await CampaignService.getCampaignByClient(clientData.uuid)

//       let newCampaignData = campaignData
//         .filter((item) => item.isParameterized == true)
//         .map((item) => ({
//           label: item.name,
//           value: item.uuid,
//           name: item.name,
//           objective: item.objective,
//           productId: item.productId,
//           targetAudienceId: item.targetAudienceId,
//           userAccountId: item.userAccountId,
//         }))

//       if (responseStatus === 200) setCampaignOptions(newCampaignData)
//     } else {
//       const { data: campaignData, responseStatus } =
//         await CampaignService.getCampaignByNotClient()

//       let newCampaignData = campaignData
//         .filter((item) => item.isParameterized == true)
//         .map((item) => ({
//           label: item.name,
//           value: item.uuid,
//           name: item.name,
//           objective: item.objective,
//           productId: item.productId,
//           targetAudienceId: item.targetAudienceId,
//           userAccountId: item.userAccountId,
//         }))

//       if (responseStatus === 200) setCampaignOptions(newCampaignData)
//     }
//   }

//   const HeaderStepRender = () => {
//     return (
//       <div>
//         <div className={styles.title_new_posts}>
//           <p className={styles.title_new_posts_label}>Nova</p>
//           <p className={styles.title_new_posts_title}>Publicação</p>
//         </div>

//         <div className={styles.title_new_posts_pass}>
//           <div>
//             <NumberOne width={'40px'} height={'40px'} />
//           </div>
//           <div>
//             <p className={styles.label_pass}>1° Passo</p>
//             <p className={styles.title_pass}>Configurações</p>
//           </div>
//         </div>
//       </div>
//     )
//   }

//   const ContentStepRender = () => {
//     return (
//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'column',

//           alignItems: 'center',

//           padding: '20px',
//           paddingBottom: '0px',

//           marginRight: '22px',
//           height: 'calc(100vh - 246px)',

//           overflowY: 'auto',
//         }}
//       >
//         <div className={styles.form_container_input}>
//           <label className={styles.title_select}>Escolha uma campanha:</label>
//           <div className={styles.box_select}>
//             <SelectSquare
//               htmlFor="select"
//               placeholder="Selecione"
//               defaultSelected={{
//                 ...campaignRef.current,
//                 value: campaignRef.current?.value,
//                 label: campaignRef.current?.label,
//               }}
//               options={campaignOptions}
//               onClickItem={({ value }) => {
//                 campaignRef.current = campaignOptions.find(
//                   (e) => e.value === value
//                 )

//                 setCampaignValue(value)
//               }}
//             />
//           </div>
//         </div>

//         <div
//           className={styles.form_container_input}
//           style={{
//             marginTop: '20px',
//           }}
//         >
//           <label className={styles.title_select}>Escolha o detalhamento:</label>
//           <div className={styles.box_select}>
//             <SelectSquare
//               htmlFor="select"
//               placeholder="Selecione"
//               defaultSelected={{
//                 value: detailRef.current?.value,
//                 label: detailRef.current?.label,
//               }}
//               options={detailsOptions}
//               onClickItem={({ value }) => {
//                 setDetailsValue(value)

//                 detailRef.current = detailsOptions.find(
//                   (e) => e.value === value
//                 )
//               }}
//             />
//           </div>
//         </div>

//         <div
//           className={styles.form_container_input}
//           style={{
//             marginTop: '20px',
//           }}
//         >
//           <label className={styles.title_select}>
//             Estágio do funil da publicação
//           </label>
//           <div
//             style={{
//               display: 'flex',
//               flexDirection: 'row',
//               gap: 10,
//             }}
//           >
//             {funnelOfSaleOptions.map((item) => (
//               <div
//                 key={item.value}
//                 className={styles.button_funnel}
//                 style={{
//                   cursor: 'pointer',

//                   border: '1px solid #1900271A',
//                   padding: '4px 8px',
//                   borderRadius: '100px',

//                   backgroundColor:
//                     funnelOfSale === item.value ? '#1900271A' : '',
//                   userSelect: 'none',
//                 }}
//                 onClick={() => {
//                   if (item.value === funnelOfSale) {
//                     funelOfSaleRef.current = undefined
//                     return setFunnelOfSale(undefined)
//                   }

//                   let returnFunnelOfSale = funnelOfSaleOptions.find(
//                     (e) => e.value === item.value
//                   )

//                   funelOfSaleRef.current = returnFunnelOfSale

//                   setSelectFunnel(item.label)
//                   setFunnelOfSale(item.value)
//                 }}
//               >
//                 {item.label}
//               </div>
//             ))}
//           </div>
//         </div>

//         <div
//           className={styles.form_container_input}
//           style={{
//             marginTop: '20px',
//           }}
//         >
//           <label className={styles.title_select}>Descrição</label>
//           <textarea
//             className={styles.textarea}
//             placeholder="Digite aqui a descrição da publicação..."
//             value={publicationDescription}
//             onChange={(e) => {
//               publicationDescriptionRef.current = e.target.value

//               setPublicationDescription(e.target.value)
//             }}
//           ></textarea>
//         </div>
//       </div>
//     )
//   }

//   const FooterStepRender = () => {
//     return (
//       <div
//         style={{
//           marginRight: '22px',
//         }}
//       >
//         <div className={styles.button_nav}>
//           <button
//             className={styles.button_back}
//             onClick={() => {
//               navigation('/NewPostPage')
//             }}
//           >
//             <p className={styles.button_title}>Cancelar e voltar</p>
//           </button>
//           <button
//             className={styles.button_next}
//             onClick={() => {
//               if (!validateForm()) return
//               setStep('stepImage')
//             }}
//           >
//             Prosseguir
//           </button>
//         </div>
//       </div>
//     )
//   }

//   return (
//     <div className={styles.container_header}>
//       {HeaderStepRender()}
//       {ContentStepRender()}
//       {FooterStepRender()}
//     </div>
//   )
// }

// export default StepFirstPass

/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useState, useEffect } from 'react'
import { CircleX, MiniSquares, NumberOne } from '../../../assets/svgs/icons'

import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { Button } from '../../../components/Button'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import { CampaignService } from '../../../services'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_NEW_CAMPAIGN: 'stepNewCampaign',
}

function StepFirstPass({
  setStep,
  clientData,
  publicationDescriptionRef,
  detailRef,
  funelOfSaleRef,
  campaignRef,
  setSelectFunnel,
}) {
  const navigation = useNavigate()
  const { t } = useTranslation()
  const [campaignOptions, setCampaignOptions] = useState([])
  const [campaignValue, setCampaignValue] = useState(undefined)

  useEffect(() => {
    loadData()
  }, [clientData])

  const loadData = async () => {
    if (clientData.uuid) {
      const { data: campaignData, responseStatus } =
        await CampaignService.getCampaignByClient(clientData.uuid)

      let newCampaignData = campaignData
        .filter((item) => item.isParameterized == true)
        .map((item) => ({
          ...item,
          label: item.name,
          value: item.uuid,
          name: item.name,
          objective: item.objective,
          productId: item.productId,
          targetAudienceId: item.targetAudienceId,
          userAccountId: item.userAccountId,
        }))

      if (responseStatus === 200) setCampaignOptions(newCampaignData)
    } else {
      const { data: campaignData, responseStatus } =
        await CampaignService.getCampaignByNotClient()

      let newCampaignData = campaignData
        .filter((item) => item.isParameterized == true)
        .map((item) => ({
          ...item,
          label: item.name,
          value: item.uuid,
          name: item.name,
          objective: item.objective,
          productId: item.productId,
          targetAudienceId: item.targetAudienceId,
          userAccountId: item.userAccountId,
        }))

      if (responseStatus === 200) setCampaignOptions(newCampaignData)
    }
  }

  const handleNavigate = (route) => {
    navigation(`${route}`)
  }

  const nextButton = async (step) => {
    setStep(step)
  }

  const handleSelectedIntem = (item) => {
    campaignRef.current = item
    // setSelectedCampaignOption(item)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      className={styles.new_capaing_container}
    >
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>{t('newPublication.title.new')}</p>
        <p className={styles.title_new_posts_title}>{t('newPublication.title.publication')}</p>
      </div>

      <div className={styles.title_steps}>
        <div>
          <NumberOne width={'40px'} height={'40px'} />
        </div>
        <div>
          <p style={{ color: '#898989', fontSize: '10px', margin: '0px' }}>
            {t('newPublication.step.first')}
          </p>
          <p
            style={{
              color: '#3E3E3E',
              fontSize: '16px',
              margin: '0px',
              fontWeight: 500,
            }}
          >
            {t('newPublication.step.selectionCampaign')}
          </p>
        </div>
      </div>
      <div className={styles.container_form}>
        <form className={styles.form_campaign}>
          <div className={styles.container_form_first_options}>
            <div className={styles.form_container_input}>
              <label>{t('newPublication.step.selectCampaign')}</label>
              <div style={{ width: '100%' }}>
                {/* <SelectSquare
                  arrowDirection="down"
                  htmlFor="select"
                  placeholder="Selecione"
                  defaultSelected={() => {
                    if (selectedCampaignOption) {
                      return {
                        label: selectedCampaignOption.label,
                        value: selectedCampaignOption.value,
                      }
                    }

                    return { label: 'Selecione', value: 1 }
                  }}
                  options={campaignOptions}
                  onClickItem={handleSelectedIntem}
                /> */}

                <SelectSquare
                  htmlFor="select"
                  placeholder={t("newPublication.step.input_placeholder")}
                  defaultSelected={{
                    ...campaignRef.current,
                    value: campaignRef.current?.value,
                    label: campaignRef.current?.label,
                  }}
                  options={campaignOptions}
                  onClickItem={({ value }) => {
                    // console.log(campaignOptions.find((e) => e.value === value))
                    let campaign = campaignOptions.find(
                      (e) => e.value === value
                    )

                    campaignRef.current = campaign

                    publicationDescriptionRef.current =
                      campaign.publicationDescription
                    detailRef.current = campaign.details
                    funelOfSaleRef.current = campaign.funnelOfSale

                    setCampaignValue(value)
                  }}
                />
              </div>
            </div>

            {campaignOptions.find((e) => e.value === campaignValue) && (
              <div className={styles.item_campaing}>
                <div className={styles.item_campaing_data}>
                  <div className={styles.item_campaing_svg}>
                    <MiniSquares />
                  </div>
                  <div className={styles.item_campaing_descrption}>
                    <p className={styles.item_campaing_label}>
                      {t('newPublication.step.campaignName')}
                    </p>
                    <p className={styles.item_campang_itle}>
                      {
                        campaignOptions.find((e) => e.value === campaignValue)
                          .label
                      }
                    </p>
                  </div>
                </div>
                <div
                  className={styles.item_campaing_remove}
                  onClick={() => {
                    setCampaignValue(undefined)
                    campaignRef.current = undefined
                  }}
                >
                  <CircleX color={'#000'} />
                </div>
              </div>
            )}

            {!campaignOptions.find((e) => e.value === campaignValue) && (
              <div className={styles.call_to_action_container}>
                <div className={styles.new_campaing}>
                  <p className={styles.new_campaing_label}>
                    {t('newPublication.step.noCampaign')}
                  </p>
                  <p className={styles.new_campaing_text}>
                    {t('newPublication.step.createCampaign')}
                  </p>
                </div>
                <div className={styles.box_button_create_campaign}>
                  <Button
                    onClick={() => {
                      navigation('/Campaign', {
                        state: { step: 'newCampaign' },
                      })
                    }}
                    variant={'secondary'}
                  >
                    {t('newPublication.step.createCampaign')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
      <div className={styles.button_next}>
        <Button
          onClick={() => {
            handleNavigate('/NewPostPage')
          }}
          variant={'secondary'}
        >
          {t('newPublication.step.cancelAndReturn')}
        </Button>

        {campaignValue && (
          <Button
            onClick={() => nextButton(STEPS.STEP_SECOND_PASS_IMAGE)}
            variant={'primary'}
          >
            {t('newPublication.step.proceed')}
          </Button>
        )}
      </div>
    </motion.div>
  )
}

export default StepFirstPass
