import styles from './styles.module.css'
import { useState, useRef, useEffect } from 'react'

import { useClient } from '../../hooks/useClient'

import StepPublicationSuccess from './StepPublicationSuccess'
import StepMyPublication from './StepMyPublication'

import { useLocation } from 'react-router-dom'

const STEPS = {
  STEP_MY_PUBLICATION: 'stepMyPublication',
  STEP_PUBLICATION_SUCCESS: 'stepPublicationSuccess',
}

export default function NewPublicationPageMyPublication() {
  const { clientData } = useClient()

  const location = useLocation()

  const [step, setStep] = useState(STEPS.STEP_MY_PUBLICATION)

  const [imageUrl, setImageUrl] = useState('')

  const publicationIdRef = useRef(undefined)

  const [oldPublicationMovie, setOldPublicationMovie] = useState(undefined)

  useEffect(() => {
    if (location?.state?.data) {
      if (location?.state?.movie) {
        setOldPublicationMovie(location?.state?.data)
      }
    }
  }, [clientData])

  return (
    <div className={styles.page}>
      {step === STEPS.STEP_MY_PUBLICATION && (
        <StepMyPublication
          setStep={setStep}
          publicationIdRef={publicationIdRef}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          clientData={clientData}
          oldPublicationMovie={oldPublicationMovie}
        />
      )}

      {step === STEPS.STEP_PUBLICATION_SUCCESS && (
        <StepPublicationSuccess
          imageUrl={imageUrl}
          publicationIdRef={publicationIdRef}
        />
      )}
    </div>
  )
}
