import { useLocation, useNavigate } from 'react-router-dom'
import { CloundArrowUp, Trash } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { TypeOfProductsServices } from '../../services'
function ProductsPageView() {
  const { state } = useLocation()

  const [product, setProduct] = useState(undefined)
  const [productOptions, setProductOptions] = useState([])
  const storage = window.localStorage

  const navigation = useNavigate()
  const handleNavigate = (route, product) => {
    navigation(route, { state: { product } })
  }

  useEffect(() => {
    if (state?.product) {
      setProduct(state?.product)
    }
  }, [state])

  useEffect(() => {
    loadTypeOfProduct()
  }, [])

  const loadTypeOfProduct = async () => {
    const typeOfProductReturn = await TypeOfProductsServices.getTypeOfProducts()

    if (typeOfProductReturn.responseStatus === 200) {
      setProductOptions(
        typeOfProductReturn?.data.map((item) => ({
          label: storage.i18nextLng == 'en' ? item.nameEn : item.name,
          value: item.id,
        }))
      )
    }
  }

  return (
    <div className={styles.container_page}>
      <div className={styles.header_page}>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>Produtos</p>
          <p className={styles.title_new_posts_title}>Gerenciando produto</p>
        </div>
        <div className={styles.buttons_container}>
          <button
            className={styles.button_new_user}
            onClick={() => handleNavigate('/ProductsPage', product)}
          >
            Editar Informações
          </button>
          <button
            className={styles.button_new_user}
            onClick={() => handleNavigate('/ProductsPage')}
          >
            Voltar
          </button>
        </div>
      </div>

      <div className={styles.container_content}>
        <div className={styles.container_form}>
          <p className={styles.title_new_posts_title}>{product?.name}</p>
          <div className={styles.container_content_form}>
            <div className={styles.box_form}>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Tipo de Produto
                </label>
                <p>
                  {
                    productOptions?.find(
                      (e) => e.value === product?.productTypeId
                    )?.label
                  }
                </p>
              </div>

              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Descrição do produto
                </label>
                <p className={styles.description_input_form}>
                  {product?.description}
                </p>
              </div>
            </div>

            <p className={styles.title_new_posts_title}>Valores</p>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Preço do produto
                </label>
                <p>{product?.value}</p>
              </div>

              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Preço Promocional
                </label>
                <p>{product?.promotionalValue}</p>
              </div>
            </div>

            <div className={styles.divider_topic}>Imagens</div>

            <div className={styles.uploads_files}>
              <div className={styles.modal_container_drag}>
                <div className={styles.modal_drag}>
                  <div className={styles.modal_drag_drop}>
                    <label htmlFor="arquivo">
                      <CloundArrowUp color={'#B900FE'} />
                      <h2 className={styles.modal_drag_drop_title}>
                        Arraste & Solte o arquivo
                      </h2>
                      <p className={styles.modal_drag_drop_sub_title}>
                        Ou selecione o arquivo no explorador
                      </p>
                    </label>
                    <input type="file" name="arquivo" id="arquivo"></input>
                  </div>
                </div>
                <button className={styles.button_upload}>Fazer Upload</button>
              </div>

              <div className={styles.box_cards}>
                <div className={styles.card_image_upload}>
                  <img
                    src="https://i.pinimg.com/564x/55/d5/a8/55d5a8953fda12b391f1dd06e9b16533.jpg"
                    alt=""
                    className={styles.image_upload}
                  />
                </div>

                <p className={styles.card_image_title_upload}>
                  Nome do arquivo.png
                </p>

                <button className={styles.card_remove_button_upload}>
                  excluir <Trash width={15} height={15} color={'#190027'} />
                </button>
              </div>
            </div>
            <div className={styles.box_button_save}>
              <button className={styles.button_save}>Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsPageView
