import { useState } from 'react'
import {
  Comment,
  CommentBubble,
  Globe,
  HeartLike,
  Like,
  PlaneShare,
  PreviewArrowRight,
  SaveFlag,
  Share,
  Trash,
} from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { object } from 'prop-types'
import { useTranslation } from 'react-i18next'


export const LayoutInstagram = ({
  image,
  legend,
  userData,
  setValueItem,
  type,
  selectedValues,
  setImageUrl,
  setTiktokMusic,
  setTiktokMusicCheckbox,
  removeImage
}) => {
  const [showButtons, setShowButtons] = useState(false)
  const {t} = useTranslation();

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  function nextImage(type) {
    if (type === 4) {
      setValueItem(1)
    } else {
      setValueItem(type + 1)
    }
  }

  function previewImage(type) {
    if (type === 1) {
      setValueItem(4)
    } else {
      setValueItem(type - 1)
    }
  }

  return (
    <div className={styles.box_image_render}>
      {/* <div className={styles.header_image_render}>
        <div className={styles.box_company_info}>
          <div className={styles.box_logo_company}>
            <img
              className={styles.image_company}
              src={userData?.photoUrl}
              alt=""
            />
          </div>
          <div className={styles.box_name_company}>
            <p className={styles.name_company}>Minha conta</p>
          </div>
        </div>
      </div> */}
      <div className={styles.body_image_render}>
        <p>{t("imageRender.instagram.instagramFeed")}</p>
        <div
          className={styles.body_image_render_container}
          onMouseEnter={() => setShowButtons(true)}
          onMouseLeave={() => setShowButtons(false)}
        >
          <img
            src={image}
            className={removeImage ? styles.blur_image : ''}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: 0,
            }}
          />
          <div className={styles.box_buttons}>

            <button
              className={styles.carouselButtonLeft}
              onClick={() => nextImage(type)}
              style={{
                opacity: showButtons ? 1 : 0,
                transition: 'opacity 0.3s',

                transform: 'rotate(180deg)',
              }}
            >
              <div style={{ marginBottom: -30,
                display: 'flex',
                background: '#000',
                borderRadius: '5px'
              }}>
                <PreviewArrowRight style={{ transform: 'rotate(180deg)' }} />
              </div>
            </button>
            {removeImage &&
              <div className={styles.box_button_delete_image}>
                <button
                  onClick={() => {
                    setImageUrl()
                    setTiktokMusic()
                    setTiktokMusicCheckbox()
                  }}
                  className={styles.button_delete_image}
                  style={{
                    opacity: showButtons ? 1 : 0,
                    transition: 'opacity 0.3s',
                  }}
                >
                  <Trash  width={25} height={25} color={"#000000"}/>
                </button>
              </div>
            }
            <button
              className={styles.carouselButtonRight}
              onClick={() => previewImage(type)}
              style={{
                opacity: showButtons ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
            >
              <div style={{
                  display: 'flex',
                  background: '#000',
                  borderRadius: '5px'
              }}>
              <PreviewArrowRight />

              </div>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.footer_image_render}>
        <div className={styles.box_icons}>
          <div>
            <HeartLike />
            <CommentBubble />
            <PlaneShare />
          </div>
          <div>
            <div>
              <SaveFlag />
            </div>
          </div>
        </div>
        <p className={styles.legend_publication}>
          {truncateString(legend, 60)}
        </p>
      </div>
    </div>
  )
}

LayoutInstagram.propTypes = {
  image: object,
}
