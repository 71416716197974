/* eslint-disable react/prop-types */
// import React from 'react'
import styles from './styles.module.css'
import PropTypes from 'prop-types'

import {
  CheckBadge,
  CircleFacebook,
  // Clock,
  // DatepikerWhite,
  Instagram,
  Linkedin,
  //Pinterest,
  TikTok,
  // Twitter,
  // Facebook,
} from '../../../../assets/svgs/icons'

export const SocialMediaStep = ({ images, data }) => {
  data = [
    // {
    //   socialMediaIcon: <Pinterest width={25} height={25} color={'#FF8B00'} />,
    //   socialMediaName: 'Pinterest',
    // },
    {
      socialMediaIcon: <Linkedin width={25} height={25} color={'#FF8B00'} />,
      socialMediaName: 'LinkedIn',
    },
    {
      socialMediaIcon: (
        <CircleFacebook width={25} height={25} color={'#FF8B00'} />
      ),
      socialMediaName: 'Facebook',
    },
    {
      socialMediaIcon: <Instagram width={25} height={25} color={'#FF8B00'} />,
      socialMediaName: 'Instagram',
    },
    {
      socialMediaIcon: <TikTok width={25} height={25} color={'#FF8B00'} />,
      socialMediaName: 'TikTok',
    },
  ]

  return (
    <div className={styles.step_container}>
      <div
        style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 10 }}
      >
        {data.map((item, index) => (
          <div key={index} style={{ display: 'flex', flex: 1 }}>
            <div style={{ display: 'flex', flex: 1, gap: 10 }}>
              <div>{item.socialMediaIcon}</div>
              <div>{item.socialMediaName}</div>
            </div>

            <div>
              <CheckBadge color={'#0FBE00'} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

SocialMediaStep.propTypes = {
  images: PropTypes.array,
}
