import axios from 'axios'
const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const postsendCode = async (body) => {
  const config = {
    headers: {},
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}forgot-password/send-code`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postsendCode', error.response.data)
      return error
    })
}

export const postVerifyCode = async (body) => {
  const config = {
    headers: {},
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}forgot-password/verify-code`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postVerifyCode', error.response.data)
      return error
    })
}

export const postResetPassword = async (body) => {
  const config = {
    headers: {},
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}/forgot-password/reset`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postResetPassword', error.response.data)
      return error
    })
}
