import styles from './styles.module.css'

import { Input } from '../../../components/Input'

import {
  CircleFacebook,
  Instagram,
  Linkedin,
  //Pinterest,
  TikTok,
  Twitter,
  XTwitter,
} from '../../../assets/svgs/icons'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const STEPS = {
  STEP_TARGET_AUDIENCE_LIST: 'targetAudienceList',
}

function TargetAudienceView({ setStep, state, setTargetAudienceItem }) {
  //const [isPinterest, setIsPinterest] = useState(false)
  const [isLinkedin, setIsLinkedin] = useState(false)
  const [isFacebook, setIsFacebook] = useState(false)
  const [isInstagram, setIsInstagram] = useState(false)
  const [isTiktok, setIsTiktok] = useState(false)
  const [isTwitter, setIsTwitter] = useState(false)

  // const navigation = useNavigate()
  // const handleNavigate = (route) => {
  //   navigation(route, { state: {} })
  // }

  const handleNavigate = () => {
    setStep(STEPS.STEP_TARGET_AUDIENCE_LIST)
    setTargetAudienceItem({})
  }

  return (
    <div className={styles.container_page}>
      <div className={styles.header_page}>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>Público-Alvo</p>
          <p className={styles.title_new_posts_title}>{state?.name}</p>
        </div>
        <div>
          <button
            className={styles.button_new_user}
            onClick={() => handleNavigate('/TargetAudience')}
          >
            Voltar
          </button>
        </div>
      </div>

      <div className={styles.container_content}>
        <div className={styles.container_form}>
          <p className={styles.title_new_posts_title}>{state?.name}</p>
          <div className={styles.container_content_form}>
            <div className={styles.box_form}>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Nome do público-alvo
                </label>
                <p>{state?.name}</p>
              </div>

              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>Genero</label>
                <p>{state?.genderId}</p>
              </div>

              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Nivel de escolaridade
                </label>
                <p>{state?.educationLevelId}</p>
              </div>
            </div>

            <p className={styles.title_new_posts_title}>Preferências</p>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Preferências de tipo de compra
                </label>
                <p>{state?.targetAudienceBrandsId}</p>
              </div>

              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Marcas que o público-alvo costuma comprar
                </label>
                <Input />
              </div>

              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Redes sociais que o público-alvo usa frequentemente
                </label>
                <div className={styles.container_social_media}>
                  {/* <div
                    className={styles.box_social_media}
                    //onClick={() => setIsPinterest(!isPinterest)}
                  >
                    <Pinterest
                      width={50}
                      height={50}
                      color={isPinterest ? '#FF8B00' : '#00000080'}
                    />
                  </div> */}
                  <div
                    className={styles.box_social_media}
                    onClick={() => setIsLinkedin(!isLinkedin)}
                  >
                    <Linkedin
                      width={50}
                      height={50}
                      color={isLinkedin ? '#FF8B00' : '#00000080'}
                    />
                  </div>
                  <div
                    className={styles.box_social_media}
                    onClick={() => setIsFacebook(!isFacebook)}
                  >
                    <CircleFacebook
                      width={50}
                      height={50}
                      color={isFacebook ? '#FF8B00' : '#00000080'}
                    />
                  </div>
                  <div
                    className={styles.box_social_media}
                    onClick={() => setIsInstagram(!isInstagram)}
                  >
                    <Instagram
                      width={50}
                      height={50}
                      color={isInstagram ? '#FF8B00' : '#00000080'}
                    />
                  </div>
                  <div
                    className={styles.box_social_media}
                    onClick={() => setIsTiktok(!isTiktok)}
                  >
                    <TikTok
                      width={50}
                      height={50}
                      color={isTiktok ? '#FF8B00' : '#00000080'}
                    />
                  </div>
                  <div
                    className={styles.box_social_media}
                    onClick={() => setIsTwitter(!isTwitter)}
                  >
                    <XTwitter
                      width={50}
                      height={50}
                      color={isTwitter ? '#FF8B00' : '#00000080'}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.box_region}>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}> Estado</label>
                <p>{state?.stateId}</p>
              </div>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}> Região</label>
                <p>{state?.regionId}</p>
              </div>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}> Cidade</label>
                <p>{state?.city}</p>
              </div>
            </div>
            <div className={styles.box_button_save}>
              <button className={styles.button_save}>Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TargetAudienceView
