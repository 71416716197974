/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import {
  CircleFacebook,
  CalendarIcon,
  DatepikerWhite,
  Hamburger,
  Instagram,
  Linkedin,
  NumberOne,
  NumberThree,
  NumberTwo,
  //Pinterest,
  Refresh,
  Twitter,
  Cross,
  CircleX,
  ArrowUp,
  ChevronDown,
  NumberFour,
  BoxChecket,
  BoxNotChecket,
  XTwitter,
} from '../../../assets/svgs/icons'
import { useState, useEffect } from 'react'
import { TikTok } from '../../../assets/svgs/icons'
import ModalComponent from '../../../components/ModalComponent'
import CalendarModal from '../../../components/CalendarModal'

import { Loading } from '../../../components/Loading'
import { motion } from 'framer-motion'

import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import {
  BusinessService,
  LogosService,
  PublicationService,
  PythonService,
  PlanService,
} from '../../../services'

import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'
import { MyUploadsService } from '../../../services'
import dayjs from 'dayjs'

const STEPS = {
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',
}

function StepFifthPass({
  setStep,
  selectedArrayImage,
  selectedArrayImageObject,

  selectedCampaignOption,
  legendList,
  headlineList,
  selectedImagesLogo,
  setSelectedImagesLogo,
}) {
  const { clientData } = useClient()

  const navigate = useNavigate()

  const [imagesSelectedsArrayObj, setImagesSelectedsArrayObj] = useState([])
  const [draggingIndex, setDraggingIndex] = useState(null)

  const [isModalOpen, setModalOpen] = useState(false)
  const [itemModal, setItemModal] = useState()

  const [loading, setLoading] = useState(false)

  const [socialMediaEvent, setSocialMediaEvent] = useState([])

  const [addLogo, setAddLogo] = useState(false)

  //const [selectedImagesLogo, setSelectedImagesLogo] = useState([])

  const socialMediaVerification = (socialTypes = '', item) => {
    const socialTypesArray = socialTypes ? socialTypes.split(',') : []

    // Mapeia cada tipo para o componente correspondente
    const components = socialTypesArray.map((type) => {
      if (type === '1') {
        return (
          <Linkedin
            key="linkedin" // Adicione uma chave única
            width={25}
            height={25}
            color={item.linkedin.active ? '#00B828' : '#E6E6E6'}
          />
        )
      }
      if (type === '2') {
        return (
          <XTwitter
            key="twitter" // Adicione uma chave única
            width={25}
            height={25}
            color={item.linkedin.active ? '#00B828' : '#E6E6E6'}
          />
        )
      }
      // if (type === '3') {
      //   return (
      //     <Pinterest
      //       key="pinterest" // Adicione uma chave única
      //       width={25}
      //       height={25}
      //       color={item.pinterest.active ? '#00B828' : '#E6E6E6'}
      //     />
      //   )
      // }
      return null // Retorna null para tipos desconhecidos
    })

    return components // Retorna o array de componentes
  }

  useEffect(() => {
    let imagesSelectedsArrayObj
    if (selectedArrayImage?.length != 0 && selectedImagesLogo?.length === 0) {
      imagesSelectedsArrayObj = selectedArrayImage.map((item, index) => {
        return {
          image: item,
          title: headlineList[index],
          id: index,
          // pinterest: {
          //   active: false,
          //   orientation: null,
          // },
          linkedin: {
            active: true,
            orientation: null,
          },
          facebook: {
            active: true,
            orientation: null,
          },
          instagram: {
            active: true,
            orientation: null,
          },
          tiktok: {
            active: true,
            orientation: null,
          },
          twitter: {
            active: true,
            orientation: null,
          },
          date: '00/00/0000',
          hour: '00:00',
          funnel: 'Topo',
        }
      })

      setImagesSelectedsArrayObj(imagesSelectedsArrayObj)
    } else {
      imagesSelectedsArrayObj = selectedImagesLogo.map((item, index) => {
        return {
          image: `data:image/png;base64,${item?.src}`,
          title: headlineList[index],
          id: index,
          // pinterest: {
          //   active: false,
          //   orientation: null,
          // },
          linkedin: {
            active: true,
            orientation: null,
          },
          facebook: {
            active: true,
            orientation: null,
          },
          instagram: {
            active: true,
            orientation: null,
          },
          tiktok: {
            active: true,
            orientation: null,
          },
          twitter: {
            active: true,
            orientation: null,
          },
          date: '00/00/0000',
          hour: '00:00',
          funnel: 'Topo',
        }
      })

      setImagesSelectedsArrayObj(imagesSelectedsArrayObj)
    }
  }, [selectedImagesLogo])

  const moverParaCima = (index) => {
    if (index === 0) return
    const novaLista = [...imagesSelectedsArrayObj]
    const item = novaLista[index]
    novaLista[index] = novaLista[index - 1]
    novaLista[index - 1] = item
    setImagesSelectedsArrayObj(novaLista)
  }

  const moverParaBaixo = (index) => {
    if (index === imagesSelectedsArrayObj.length - 1) return
    const novaLista = [...imagesSelectedsArrayObj]
    const item = novaLista[index]
    novaLista[index] = novaLista[index + 1]
    novaLista[index + 1] = item
    setImagesSelectedsArrayObj(novaLista)
  }

  const handleOpenModal = (item) => {
    setItemModal(item)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)
    }
    if (navigation === 'next') {
      // setStep(STEPS.STEP_FOURTH_PASS_IMAGE)
    }
  }

  const handleSelectDate = (data) => {
    const newImagesSelectedsArrayObj = imagesSelectedsArrayObj.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          date: data.formatDate,
          hour: data.valueHour + ':' + data.valueMinute,
          valueHour: data.valueHour,
          valueMinute: data.valueMinute,
          selectedDate: data.selectedDate,
          sketch: data.sketch,
          socialTypes: data.socialTypes,
        }
      }
      return item
    })

    setImagesSelectedsArrayObj(newImagesSelectedsArrayObj)
  }

  const handleCancelSelectDate = (data) => {
    const newImagesSelectedsArrayObj = imagesSelectedsArrayObj.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          date: '00/00/0000',
          hour: '00:00',
        }
      }
      return item
    })

    setImagesSelectedsArrayObj(newImagesSelectedsArrayObj)
  }

  const returnUploadImageBucket = async (data) => {
    function extractImageData(base64String) {
      // Dividir a string base64 para separar o cabeçalho da imagem
      const parts = base64String.split(',')
      const header = parts[0]
      const base64Data = parts[1]

      // Extrair o formato do arquivo e o tipo de conteúdo
      const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
      const fileContentType = matches[0]
      const fileFormat = fileContentType.split('/')[1]

      // Retornar os dados no formato desejado
      return {
        fileName: 'publication',
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      }
    }

    let body = extractImageData(data)

    // Adicione um log para verificar o corpo

    const publicationImageBucket =
      await PublicationService.postPublicationImage(body)

    if (publicationImageBucket?.responseStatus === 200) {
      return publicationImageBucket?.data
    } else {
      console.log(publicationImageBucket?.responseStatus)
    }
  }

  const handleSavePublication = async () => {
    imagesSelectedsArrayObj.forEach(async (element, index) => {
      let businessId

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()

        businessId = data[0]?.uuid
      }

      // const dateSelected = new Date(element.selectedDate)
      // const date = new Date(dateSelected)

      // dateSelected.setHours(element.valueHour)
      // dateSelected.setMinutes(element.valueMinute)

      const dateSelected = dayjs(element.selectedDate)
        .hour(element.valueHour)
        .minute(element.valueMinute)

      let publicationImageBucket = await returnUploadImageBucket(element.image)

      if (publicationImageBucket) {
        let publicationBody = {
          headline: headlineList[index],
          legend: legendList[index],
          publicationImageUrl: publicationImageBucket?.fileUrl,
          publicationImageUrlName: publicationImageBucket?.fileName,
          sketch: element?.sketch,
          scheduleDate: dateSelected,
          publicationDate: dateSelected,
          campaignId: selectedCampaignOption?.value,
          objective: selectedCampaignOption?.objective,
          businessClientId: clientData?.uuid ? clientData?.uuid : undefined,
          socialTypes: '',
          guestId: JSON.parse(window.localStorage.getItem('userData'))
            .guestUserId,
        }

        let publicationResponse = await PublicationService.postPublication(
          businessId,
          element.socialTypes,
          publicationBody
        )

        const imagePrincipalLayer = selectedArrayImageObject[
          index
        ]?.tempImageLayers?.camadas.find((image) => image.type === 'principal')

        if (imagePrincipalLayer) {
          const imagePrincipalLayerBody = {
            publicationId: publicationResponse?.data?.uuid,
            fileName: 'ImagePrincipal',
            fileFormat: 'jpeg',
            fileContentType: 'image/jpeg',
            fileBase64: imagePrincipalLayer?.imageId,
          }

          await MyUploadsService.postMyUploads(imagePrincipalLayerBody)
        }

        if (publicationResponse?.responseStatus === 200) {
          // notifySuccessCustom('Publicação agendada com sucesso!')

          if (clientData?.uuid) {
            navigate('/SchedulePublicationPage')
          } else {
            navigate('/SchedulingPage', {
              state: {
                dateSchedule: dateSelected,
              },
            })
          }
        } else {
          notifyErrorCustom('Erro ao agendar publicação!')
        }
      } else {
        notifyErrorCustom('Erro ao salvar arte!')
      }
    })

    notifySuccessCustom('Campanha agendada com sucesso!')

    setLoading(false)
  }

  const handleAddLogo = async () => {
    setAddLogo(!addLogo)

    let businessId

    if (addLogo === false) {
      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)

        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()
        businessId = data[0]?.uuid
      }

      const { data: dataLogo, responseStatus: responseStatusLogo } =
        await LogosService.getLogosById(businessId)

      const { data: dataLogoBase64, responseStatus: responseStatusLogoBase64 } =
        await LogosService.getLogosByBase64(dataLogo[0].uuid)

      let parts
      var base64Data = []
      for (let i = 0; i < selectedArrayImage?.length; i++) {
        parts = selectedArrayImage[i].split(',')
        base64Data = [...base64Data, parts[1]]
      }

      const bodyToLogo = {
        art_width: 1080,
        art_height: 1350,
        logo_base64: dataLogoBase64,
        images: base64Data,
      }

      let generateLogoToImagesReturn = await PythonService.postAddLogoToImage(
        bodyToLogo
      )

      setSelectedImagesLogo(generateLogoToImagesReturn?.data?.data)
    } else {
      setSelectedImagesLogo([])
    }
  }

  const handleDragStart = (e, index) => {
    // Inicia o arrastar
    e.dataTransfer.setData('index', index)

    setDraggingIndex(index)
  }

  const handleDragOver = (e, index) => {
    // Permite a soltura
    e.preventDefault()
  }

  const handleDrop = (e, dropIndex) => {
    // Finaliza o arrastar e soltar
    const dragIndex = parseInt(e.dataTransfer.getData('index'))

    // Cria uma cópia da lista atual
    const newList = [...imagesSelectedsArrayObj]

    // Remove o item arrastado da lista
    const draggedItem = newList.splice(dragIndex, 1)[0]

    // Insere o item arrastado na nova posição
    newList.splice(dropIndex, 0, draggedItem)

    // Atualiza o estado com a nova lista ordenada
    setImagesSelectedsArrayObj(newList)

    // Remove a classe de arrastar
    setDraggingIndex(null)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container_header}
    >
      <Loading enable={loading} />

      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Nova</p>
        <p className={styles.title_new_posts_title}>Campanha</p>
      </div>
      <div className={styles.title_steps}>
        <div className={styles.first_pass}>
          <div>
            <NumberOne width={'40px'} height={'40px'} />
          </div>
          <div className={styles.first_pass_title}>
            <p className={styles.first_pass_title_text}>1° Passo</p>
            <p className={styles.first_pass_subtitle}>Configurações</p>
          </div>
        </div>

        <div className={styles.second_pass}>
          <div>
            <NumberTwo width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>2° Passo</p>
            <p className={styles.first_pass_subtitle}>Imagem</p>
          </div>
        </div>

        <div className={styles.third_pass}>
          <div>
            <NumberThree width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>3° Passo</p>
            <p className={styles.first_pass_subtitle}>Legendas</p>
          </div>
        </div>

        <div className={styles.fourth_pass}>
          <div>
            <NumberFour width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>4° Passo</p>
            <p className={styles.first_pass_subtitle}>Agendamento</p>
          </div>
        </div>
      </div>

      <div className={styles.container_content}>
        {imagesSelectedsArrayObj.map((item, index) => (
          <div
            key={item.id}
            //className={styles.card_schedule}
            className={`${styles.card_schedule} ${
              draggingIndex === index ? styles.dragging : ''
            }`}
            draggable="true"
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDrop={(e) => handleDrop(e, index)}
          >
            <div className={styles.box_hamburger}>
              {/* <Hamburger color={'#000'} /> */}
              <div
                style={{
                  padding: '0px 10px',
                  cursor: 'grabbing',
                }}
              >
                <Hamburger color={'#000'} />
              </div>
            </div>
            <div className={styles.box_image_publication}>
              <img
                src={item.image}
                alt={item.id}
                className={styles.image_publication}
              />
            </div>
            <div className={styles.box_title_publication}>
              <p className={styles.title_publication}>{item.title}</p>
            </div>
            <div className={styles.box_date}>
              <p className={styles.date_label}>Data</p>
              <p className={styles.date_text}>{item.date}</p>
            </div>
            <div className={styles.box_hour}>
              <p className={styles.hour_label}>Horário</p>
              <p className={styles.hour_text}>{item.hour}</p>
            </div>
            <div className={styles.box_funnel_stage}>
              <p className={styles.funnel_label}>Estagio do funil</p>
              <p className={styles.funnel_text}>{item.funnel}</p>
            </div>
            <div className={styles.box_platforms}>
              <p className={styles.platforms_label}>Plataformas</p>
              <div className={styles.box_platforms_icons}>
                {socialMediaVerification(
                  imagesSelectedsArrayObj[index]?.socialTypes,
                  item
                )}
                {/* {socialMediaEvent.pinterest && (
                  <Pinterest
                    width={25}
                    height={25}
                    color={item.pinterest.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                {socialMediaEvent.linkedin && (
                  <Linkedin
                    width={25}
                    height={25}
                    color={item.linkedin.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                
                {socialMediaEvent.facebook && (
                  <CircleFacebook
                    width={25}
                    height={25}
                    color={item.facebook.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                {socialMediaEvent.instagram && (
                  <Instagram
                    width={25}
                    height={25}
                    color={item.instagram.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                {socialMediaEvent.tiktok && (
                  <TikTok
                    width={25}
                    height={25}
                    color={item.tiktok.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                {socialMediaEvent.twitter && (
                  <Twitte
                    width={25}
                    height={25}
                    color={item.twitter.active ? '#00B828' : '#E6E6E6'}
                  />
                )} */}
              </div>
            </div>
            <div className={styles.box_button_schedule}>
              {item.date === '00/00/0000' ? (
                <button
                  className={styles.button_schedule}
                  onClick={() => handleOpenModal(item)}
                >
                  Agendar publicação <CalendarIcon />
                </button>
              ) : (
                <button
                  className={styles.button_cancel}
                  onClick={() => {
                    handleCancelSelectDate(item)
                  }}
                >
                  Cancelar <CircleX width={20} height={20} color={'#fff'} />
                </button>
              )}
            </div>
          </div>
        ))}

        <div className={styles.box_add_logo} onClick={handleAddLogo}>
          {addLogo ? <BoxChecket /> : <BoxNotChecket borderColor={'#000'} />}
          <p className={styles.box_add_logo_label}>
            Adicionar logo em todas as artes
          </p>
        </div>
      </div>
      <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal}>
        <CalendarModal
          handleCloseModal={handleCloseModal}
          item={itemModal}
          imageUrl={itemModal?.image}
          multiplePublication={true}
          handleSelectDate={handleSelectDate}
          socialMediaEvent={socialMediaEvent}
          setSocialMediaEvent={setSocialMediaEvent}
        />
      </ModalComponent>
      <div className={styles.button_nav}>
        <button
          className={styles.button_back}
          onClick={() => {
            nextButton('back')
          }}
        >
          <p className={styles.button_title}>Voltar</p>
        </button>

        <button
          className={styles.button_next}
          onClick={() => {
            // nextButton('next')
            let verifyDate = imagesSelectedsArrayObj.find((item) => {
              return item.date === '00/00/0000'
            })

            if (verifyDate) {
              notifyErrorCustom('Selecione uma data para todas as imagens!')
              return
            }

            setLoading(true)

            handleSavePublication()
          }}
        >
          <p className={styles.button_title}>Prosseguir</p>
        </button>
      </div>
    </motion.div>
  )
}

export default StepFifthPass
