import { useState } from 'react'
import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'

import { motion } from 'framer-motion'

import { useTranslation } from 'react-i18next'

import { ForgotPasswordService } from '../../../services'

function StepVerifyEmail({ email, setStep, setCode, code }) {
  const [isLoading, setIsLoading] = useState(false)

  const STEPS = {
    STEP_EMAIL: 'stepEmail',
    STEP_PASS_PASSWORD: 'stepPassword',
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      const response = await ForgotPasswordService.postVerifyCode({
        email,
        code,
      })

      console.log('response', response)

      if (response.responseStatus === 200) {
        setStep(STEPS.STEP_PASS_PASSWORD)
      } else {
        notifyErrorCustom(
          t('forgotPasswordStepCode.forgot_password_code_error')
        )
      }

      setIsLoading(false)
    } else {
      setStep(STEPS.STEP_EMAIL)
    }
  }

  const { t } = useTranslation()
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div>{/* <PreviousPass steps={stepsArray}/> */}</div>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>
            {t('forgotPasswordStepCode.forgot_password_code_title')}
          </p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
            {t('forgotPasswordStepCode.forgot_password_code_subtitle')}
          </h1>

          <div className={styles.box_input_email}>
            <p>
              {t(
                'forgotPasswordStepCode.forgot_password_code_subtitle_description'
              )}
            </p>
            <input
              className={styles.input_email}
              type="text"
              value={code}
              placeholder={t(
                'forgotPasswordStepCode.forgot_password_code_placeholder'
              )}
              onChange={(e) => setCode(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={styles.box_button}>
          <div />
          <button
            disabled={isLoading}
            className={styles.next_button}
            onClick={() => {
              setIsLoading(true)
              handleNavigate('next')
            }}
          >
            {t('next_buttom')}
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepVerifyEmail
