import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import { TikTok } from '../../assets/svgs/icons'
import { useState, useEffect, useRef } from 'react'
const TikTokModal = ({
  isOpen,
  setOpenTerms,
  setConfirmTerms,
  handleFinish,
}) => {
  if (!isOpen) return null // Se a modal não estiver aberta, nada é renderizado

  const { t } = useTranslation()

  const handleCancel = () => {
    setOpenTerms(false)
  }
  const handleConfirm = () => {
    setOpenTerms(false)
    setConfirmTerms(true)
  }

  const [tiktokMusicCheckbox, setTiktokMusicCheckbox] = useState(false)

  return (
    <div className={styles.overlayTikTok}>
      <div className={styles.modalTiktok}>
        <div className={styles.tiktokHeader}>
          <TikTok color={'#6A0098'} width={30} height={30} />
          <h2>{t('tiktok_music_confirmation_title')}</h2>
        </div>
        <label
          className={styles.checkboxLabel}
          onClick={(e) => {
            return
          }}
        >
          <input
            type="checkbox"
            className={styles.checkbox}
            onClick={(e) => {
              e.stopPropagation()
              tiktokMusicCheckbox
                ? setTiktokMusicCheckbox(false)
                : setTiktokMusicCheckbox(true)
            }}
          />
          <p>
            {t('tiktok_music_confirmation')}{' '}
            <a
              target="_blank"
              href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
              rel="noreferrer"
            >
              {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink3')}{' '}
            </a>
          </p>
        </label>
        <div className={styles.buttonsTikTok}>
          <button className={styles.cancelBtn} onClick={handleCancel}>
            {t('tiktok_music_cancel')}
          </button>
          <button
            className={
              tiktokMusicCheckbox ? styles.confirmBtn : styles.confirmBtnDisable
            }
            onClick={handleConfirm}
          >
            {t('tiktok_music_confirm')}
          </button>
        </div>
      </div>
    </div>
  )
}

// const Apps = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false)

//   const openModal = () => setIsModalOpen(true)
//   const closeModal = () => setIsModalOpen(false)
//   const handleConfirm = () => {
//     alert('Você concordou com as políticas de música do TikTok!')
//     setIsModalOpen(false)
//   }

//   // return (
//   //   <div>
//   //     {/* Botão que chama a função openModal para abrir a modal */}
//   //     <button onClick={openModal}>Abrir Modal</button>

//   //     {/* Modal passa as funções para controlar seu comportamento */}
//   //     <TikTokModal
//   //       isOpen={isModalOpen}
//   //       onClose={closeModal}
//   //       onConfirm={handleConfirm}
//   //     />
//   //   </div>
//   // )
// }

export default TikTokModal
