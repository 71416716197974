/* eslint-disable react/prop-types */

import { useRef, useState, useEffect } from 'react'
import styles from './styles.module.css'

import lazyLoadingAnimation from '../../assets/lottie/lazy-loading-animation.json'
import Lottie from 'lottie-react'

import SimpleEditionModal from '../../components/SimpleEditionModalTemplate'

const CarouselDashboard = ({
  items,
  setIsModalOpen,
  setImageUrl,
  setImageLayers,
  setIndexModalSelected,
}) => {
  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [dragThreshold, setDragThreshold] = useState(5) // Threshold to differentiate between drag and click
  const [isClick, setIsClick] = useState(true) // Track if the action is click or drag

  useEffect(() => {}, [])

  const handleMouseDown = (event) => {
    setIsDragging(true)
    setStartX(event.clientX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
    carouselRef.current.style.scrollBehavior = 'unset'
    setIsClick(true) // Assume it's a click initially
  }

  const handleMouseMove = (event) => {
    if (!isDragging) return
    const x = event.clientX - carouselRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0
    carouselRef.current.scrollLeft = scrollLeft - scrollOffset

    // If the mouse moves more than the drag threshold, it's a drag, not a click
    if (Math.abs(event.clientX - startX) > dragThreshold) {
      setIsClick(false)
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    carouselRef.current.style.scrollBehavior = 'smooth'
  }

  const handleItemClick = (item, index) => {
    console.log('itemDashboar', item)
    if (isClick) {
      setIsModalOpen(true)
      setImageUrl('data:image/png;base64,' + item?.srcComTratativa)
      setImageLayers(item)
      setIndexModalSelected(index)
    }
  }

  const renderItems = () => {
    return (
      <>
        {items?.map((item, index) => (
          <div className={styles.carousel_items} key={index} draggable="false">
            <div className={styles.carousel_container_image} draggable="false">
              <div className={styles.slide_container} draggable="false">
                {!item?.srcComTratativa && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                      }}
                    >
                      <Lottie
                        animationData={lazyLoadingAnimation}
                        style={{ width: 100, height: 100 }}
                        loop={true}
                        autoplay={true}
                      />
                    </div>
                  </div>
                )}
              </div>

              {item?.srcComTratativa && (
                <div
                  style={{
                    display: 'flex',
                    cursor: 'pointer',

                    // backgroundColor: 'red',

                    width: '100%',
                    height: '100%',
                  }}
                  onClick={() => handleItemClick(item, index)}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '20px',
                    }}
                    src={'data:image/png;base64,' + item?.srcComTratativa}
                    alt={item?.alt}
                    draggable="false"
                  />
                </div>
              )}
            </div>

            <div className={styles.box_button}></div>
          </div>
        ))}
      </>
    )
  }

  return (
    <div
      className={styles.carousel_container}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      draggable="false"
    >
      <div className={styles.carousel} ref={carouselRef}>
        {renderItems()}
      </div>
    </div>
  )
}

export default CarouselDashboard
