/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import { Pen2, PencilSquare, RoundedArrow } from '../../../../assets/svgs/icons'
import { Input, IconButton, ColorPalete, Button } from '../../..'
import styles from './styles.module.css'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

export const ColorPalletStep = ({
  imageLayers,
  // colors,
  editTemplate,
  editTemplateCorPallet,
  setLoading,
}) => {
  const [colorsPalette, setColorsPalette] = useState([])

  useEffect(() => {
    setColorsPalette(imageLayers?.coresEmDestaqueNaArte || [])
  }, [imageLayers])

  const ArtColors = () => {
    const [tempColor, setTempColor] = useState({})

    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <label
            style={{
              color: '#898989',
            }}
          >
            Paleta de Cores
          </label>

          <div
            style={{
              border: '0.6px solid #898989',
              padding: 8,
              borderRadius: 10,
              flex: 1,
              display: 'flex',
              gap: 10,
            }}
          >
            {colorsPalette.map((color, index) => {
              return (
                <input
                  key={index}
                  type="color"
                  className={styles.colorInput}
                  value={tempColor[index] || color.color || '#000000'}
                  onChange={(e) => {
                    setTempColor({
                      ...tempColor,
                      [index]: e.target.value,
                    })
                  }}
                  onBlur={(e) => {
                    const newColors = colorsPalette.map((item, i) => {
                      if (i === index) {
                        return {
                          ...item,
                          color: e.target.value,
                        }
                      }

                      return item
                    })
                    setColorsPalette(newColors)
                  }}
                />
              )
            })}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          <div
            onClick={() => {}}
            style={{
              backgroundColor: '#160125',
              color: 'white',
              padding: '6px 14px',
              borderRadius: 4,
              cursor: 'pointer',
            }}
          >
            <div
              onClick={() => {
                setLoading(true)
                editTemplateCorPallet(colorsPalette)
              }}
              style={{
                color: '#dfdfdf',
                fontWeight: '500',
                fontFamily: 'poppins',
              }}
            >
              Aplicar Cores
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HandleInputItem = ({ param, index }) => {
    const [value, setValue] = useState(param?.value)

    useEffect(() => {
      setValue(param?.value)
    }, [param])

    return (
      <div className={styles.inputContainer}>
        <div className={styles.labelContainer}>
          <label className={styles.label}>{param.name}</label>
          <PencilSquare size="31" color={'rgba(255, 139, 0, 1)'} />
        </div>

        <input
          type="text"
          name={param.name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setLoading(true)
              editTemplate(index, value)
            }
          }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: -4,
          }}
        >
          <div
            onClick={() => {
              setLoading(true)
              editTemplate(index, value)
            }}
            style={{
              backgroundColor: '#E6E6E6',
              padding: '6px 12px',

              borderRadius: 4,
              cursor: 'pointer',

              color: '#190027',

              fontFamily: 'poppins',
              fontWeight: '500',
              fontSize: 12,

              border: '1px solid #6A0098',
            }}
          >
            Alterar Texto
          </div>
        </div>
      </div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.form}
    >
      {imageLayers &&
        imageLayers?.params &&
        imageLayers?.params.map((param, index) => {
          if (param.type !== 'texto') return
          return <HandleInputItem key={index} param={param} index={index} />
        })}

      <ArtColors />

      <div className={styles.labelContainer}>
        <div className={styles.colorPaleteContainer}></div>
      </div>
    </motion.div>
  )
}

ColorPalletStep.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  colors: PropTypes.array,
  onClose: PropTypes.func,
  onChangeTittle: PropTypes.func,
  onChangeSubtitle: PropTypes.func,
  onClickButton: PropTypes.func,
}
