import { Comment, Like, Share, Stars } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { object, node, func } from 'prop-types'
import { LayoutFacebook } from './layouts/layoutFacebook'
import { LayoutInstagram } from './layouts/layoutInstagram'
import { LayoutFacebookStories } from './layouts/layoutFacebookStories'
import { LayoutInstagramStories } from './layouts/layoutInstagramStories'
import { LayoutTikTok } from './layouts/layoutTikTok'
import { LayoutDefault } from './layouts/layoutDefault'
import { UserAccountsService } from '../../services'
import { useEffect, useState } from 'react'

export const ImageRender = ({
  image,
  type,
  legend = '',
  setValueItem,
  selectedValues,
  setImageUrl,
  setTiktokMusic,
  setTiktokMusicCheckbox,
  removeImage
}) => {
  const [imagesToRender, setImagesToRender] = useState({})

  useEffect(() => {
    hadleLoadUserData()
  }, [])

  const hadleLoadUserData = async () => {
    const { data: dataAccount, responseStatus: responseStatusAccount } =
      await UserAccountsService.getUserAccountsByLogin()

    setImagesToRender(dataAccount)
  }

  const renderImageSocialMedia = () => {
    switch (type) {
      case 1:
        return (
          <LayoutDefault
            image={image}
            legend={legend}
            userData={imagesToRender}
            setValueItem={setValueItem}
            type={type}
            selectedValues={selectedValues}
            setImageUrl={setImageUrl}
            setTiktokMusic={setTiktokMusic}
            setTiktokMusicCheckbox={setTiktokMusicCheckbox}
            removeImage={removeImage}
          />
        )
        break
      case 2:
        return (
          <LayoutInstagram
            image={image}
            legend={legend}
            userData={imagesToRender}
            setValueItem={setValueItem}
            type={type}
            selectedValues={selectedValues}
            setImageUrl={setImageUrl}
            setTiktokMusic={setTiktokMusic}
            setTiktokMusicCheckbox={setTiktokMusicCheckbox}
            removeImage={removeImage}
          />
        )
        break

      case 3:
        return (
          <LayoutInstagramStories
            image={image}
            legend={legend}
            userData={imagesToRender}
            setValueItem={setValueItem}
            type={type}
            selectedValues={selectedValues}
            setImageUrl={setImageUrl}
            setTiktokMusic={setTiktokMusic}
            setTiktokMusicCheckbox={setTiktokMusicCheckbox}
            removeImage={removeImage}
          />
        )
        break
      case 4:
        return (
          <LayoutTikTok
            image={image}
            legend={legend}
            userData={imagesToRender}
            setValueItem={setValueItem}
            type={type}
            selectedValues={selectedValues}
            setImageUrl={setImageUrl}
            setTiktokMusic={setTiktokMusic}
            setTiktokMusicCheckbox={setTiktokMusicCheckbox}
            removeImage={removeImage}
          />
        )
        break
      case 4:
        return (
          <LayoutFacebook
            image={image}
            legend={legend}
            userData={imagesToRender}
            setImageUrl={setImageUrl}
            setTiktokMusic={setTiktokMusic}
            setTiktokMusicCheckbox={setTiktokMusicCheckbox}
            removeImage={removeImage}
          />
        )
        break

      case 5:
        return (
          <LayoutFacebookStories
            image={image}
            legend={legend}
            userData={imagesToRender}
            setImageUrl={setImageUrl}
            setTiktokMusic={setTiktokMusic}
            setTiktokMusicCheckbox={setTiktokMusicCheckbox}
            removeImage={removeImage}
          />
        )
        break

      default:
        break
    }
  }

  return renderImageSocialMedia()
}

ImageRender.propTypes = {
  image: object,
}
