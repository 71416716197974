/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { InterrogationCircleIcon } from '../../assets/svgs/icons'
import PropTypes from 'prop-types'
import {
  getAllBusinessNiche,
  postBusinessNiche,
  putBusinessNiche,
} from '../../services/businessNicheService'
import {
  getBusinessByLogin,
  getBusinessByClientId,
  putBusiness,
  postBusiness,
} from '../../services/business'
import { isObjEqual } from '../../utils/verifyObject'
import * as yup from 'yup'
import { notifyErrorCustom } from '../../utils/notify'
import { ButtonNavigateStep } from '../../components/ButtonNavigateStep'
import { CustomSelect } from '../../components/Select/CustomSelect'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepBusinessInformation({ setStep, clientData }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const businessList = [
    {
      value: 'Advocacia e Serviços Jurídicos',
      valueEn: 'Law and Legal Services',
    },
    {
      value: 'Agricultura e Agronegócio',
      valueEn: 'Agriculture and Agribusiness',
    },
    { value: 'Alimentos e Bebidas', valueEn: 'Food and Beverages' },
    { value: 'Alimentos Orgânicos', valueEn: 'Organic Food' },
    { value: 'Arte e Cultura', valueEn: 'Art and Culture' },
    { value: 'Automotivo', valueEn: 'Automotive' },
    { value: 'Barbearia', valueEn: 'Barbershop' },
    { value: 'Beleza e Salão', valueEn: 'Beauty and Salon' },
    { value: 'Cafeteria', valueEn: 'Cafeteria' },
    {
      value: 'Coaching e Desenvolvimento Pessoal',
      valueEn: 'Coaching and Personal Development',
    },
    { value: 'Educação', valueEn: 'Education' },
    { value: 'Entretenimento', valueEn: 'Entertainment' },
    { value: 'Esportes e Fitness', valueEn: 'Sports and Fitness' },
    {
      value: 'Imóveis e Construção Civil',
      valueEn: 'Real Estate and Construction',
    },
    { value: 'Indústria Farma', valueEn: 'Pharmaceutical Industry' },
    { value: 'Joias', valueEn: 'Jewelry' },
    { value: 'Jogos / Gaming', valueEn: 'Games / Gaming' },
    { value: 'Marketing e Publicidade', valueEn: 'Marketing and Advertising' },
    { value: 'Moda e Vestuário', valueEn: 'Fashion and Clothing' },
    { value: 'Móveis e Decoração', valueEn: 'Furniture and Decoration' },
    { value: 'Pet Care', valueEn: 'Pet Care' },
    { value: 'Saúde e Bem-estar', valueEn: 'Health and Well-being' },
    { value: 'Serviços Empresariais', valueEn: 'Business Services' },
    { value: 'Serviços Financeiros', valueEn: 'Financial Services' },
    { value: 'Serviços de RH', valueEn: 'HR Services' },
    {
      value: 'Sustentabilidade e Energia',
      valueEn: 'Sustainability and Energy',
    },
    { value: 'Tecnologia', valueEn: 'Technology' },
    { value: 'Viagem e Turismo', valueEn: 'Travel and Tourism' },
    { value: 'Designers', valueEn: 'Designers' },
    { value: 'Notícias e Jornalismo', valueEn: 'News and Journalism' },
    { value: 'Política', valueEn: 'Politics' },
  ]

  const [businessNichesOptions, setBusinessNichesOptions] = useState([])

  const [businessInformationData, setBusinessInformationData] = useState({})
  const [
    businessInformationDataToCompare,
    setBusinessInformationDataToCompare,
  ] = useState({})

  const businessInformationDataScheema = yup.object().shape({
    businessNicheId: yup
      .string()
      .required(t('step_business_information.business_niche_required')),
    subNiche: yup
      .string()
      .required(t('step_business_information.sub_niche_required')),
    description: yup
      .string()
      .required(t('step_business_information.description_required')),
  })

  const validateFields = async () => {
    const isValid = await businessInformationDataScheema
      .validate(businessInformationData)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })

    return isValid
  }

  useEffect(() => {
    loadBusinessNiches()
    loadExistentBusiness()
  }, [])

  const loadBusinessNiches = async () => {
    const storage = window.localStorage
    let data = await getAllBusinessNiche()
    console.log('DataNicho', data)

    if (data.data.length <= 0) {
      console.log('DataNicho1', data)
      for (const niche of businessList) {
        const bodyNiche = {
          name: niche.value,
          description: niche.value,
          descriptionEn: niche.valueEn,
        }
        await postBusinessNiche(bodyNiche)
      }
      const { data, responseStatus } = await getAllBusinessNiche()

      if (responseStatus === 200) {
        setBusinessNichesOptions(
          data.map((e) => ({
            label: storage.i18nextLng == 'en' ? e.descriptionEn : e.description,
            value: e.uuid,
          }))
        )
      }
    } else {
      if (!data.data[0].descriptionEn) {
        let i = 0
        for (const niche of businessList) {
          const bodyNiche = {
            name: niche.value,
            description: niche.value,
            descriptionEn: niche.valueEn,
          }
          await putBusinessNiche(data.data[i].uuid, bodyNiche)
          i++
        }
        data = await getAllBusinessNiche()
      }

      if (data.responseStatus === 200) {
        setBusinessNichesOptions(
          data.data.map((e) => ({
            label: storage.i18nextLng == 'en' ? e.descriptionEn : e.description,
            value: e.uuid,
          }))
        )
      }
    }
  }

  const loadExistentBusiness = async () => {
    if (clientData?.uuid) {
      const { data, responseStatus } = await getBusinessByClientId(
        clientData?.uuid
      )
      if (responseStatus === 200 && data) {
        if (data.length > 0) {
          setBusinessInformationData(data[0])
          setBusinessInformationDataToCompare(data[0])
        }
      }
    } else {
      const { data, responseStatus } = await getBusinessByLogin()
      if (responseStatus === 200 && data) {
        if (data.length > 0) {
          setBusinessInformationData(data[0])
          setBusinessInformationDataToCompare(data[0])
        }
      }
    }
  }

  const handleChangeBusinessInformation = (id, value) => {
    setBusinessInformationData((prev) => ({ ...prev, [id]: value }))
  }

  const onSubmitBusiness = async () => {
    // if (!(await validateFields())) return

    if (
      businessInformationData.uuid &&
      !isObjEqual(businessInformationData, businessInformationDataToCompare)
    ) {
      const { uuid, ...rest } = businessInformationData

      if (rest.description === '') {
        notifyErrorCustom('O campo Descrição não pode estar vazio')
        return
      }

      if (rest.subNiche === '') {
        notifyErrorCustom('O campo sub-nicho não pode estar vazio')
        return
      }

      delete rest.userAccountId
      delete rest.createdAt
      delete rest.updatedAt
      delete rest.deletedAt
      delete rest.BusinessNiche
      delete rest.default
      if (!JSON.parse(window.localStorage.getItem('userData')).guestUserId) {
        delete rest.guestId
      }

      if (rest.businessClientId === null) {
        delete rest.businessClientId
      }

      delete rest.campaignId

      await putBusiness(uuid, rest)
    } else {
      if (!businessInformationData?.uuid) {
        let newBody = { ...businessInformationData }

        if (!newBody.businessNicheId) {
          notifyErrorCustom('O campo Nicho não pode estar vazio')
          return
        }

        if (!newBody.description) {
          notifyErrorCustom('O campo Descrição não pode estar vazio')
          return
        }

        if (!newBody.subNiche) {
          notifyErrorCustom('O campo sub-nicho não pode estar vazio')
          return
        }

        if (clientData?.uuid) {
          if (JSON.parse(window.localStorage.getItem('userData')).guestUserId) {
            newBody = {
              ...newBody,
              businessClientId: clientData?.uuid,
              guestId: JSON.parse(window.localStorage.getItem('userData'))
                .guestUserId,
            }
          } else {
            newBody = {
              ...newBody,
              businessClientId: clientData?.uuid,
            }
          }
        }

        await postBusiness({ ...newBody })
      }
    }

    setStep(STEPS.STEP_BRANDING)
  }

  return (
    <div className={styles.page}>
      {/* <p className={styles.title_text}>Informações sobre o seu negócio</p> */}
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>
          {t('step_business_information.title')}
        </p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <form className={styles.form_business_information}>
        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          {t('step_business_information.select_niche')}
        </p>
        {/* <select
          id="businessNicheId"
          onChange={({ target: { id, value } }) =>
            handleChangeBusinessInformation(id, value)
          }
          value={businessInformationData.businessNicheId}
          className={styles.dropdown_nicho}
        >
          <option value="">Selecione...</option>
          {businessNichesOptions.map((e) => (
            <option key={e.uuid} value={e.value}>
              {e.label}
            </option>
          ))}
        </select> */}

        <CustomSelect
          placeholder={t('step_business_information.select_niche_placeholder')}
          id="businessNicheId"
          arrowDirection="down"
          options={businessNichesOptions}
          // defaultSelected={businessNichesOptions}
          defaultSelected={businessNichesOptions.find(
            (e) => e.value === businessInformationData.businessNicheId
          )}
          fontSize={12}
          onClickItem={({ value }) =>
            handleChangeBusinessInformation('businessNicheId', value)
          }
        />

        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          {t('step_business_information.input_sub_niche')}
        </p>
        <input
          id="subNiche"
          onChange={({ target: { id, value } }) =>
            handleChangeBusinessInformation(id, value)
          }
          value={businessInformationData.subNiche}
          className={styles.input}
          placeholder={t(
            'step_business_information.input_sub_niche_placeholder'
          )}
        ></input>

        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          {t('step_business_information.input_description')}
        </p>
        <textarea
          style={{ height: 200 }}
          className={styles.input}
          placeholder={t(
            'step_business_information.input_description_placeholder'
          )}
          id="description"
          value={businessInformationData.description}
          onChange={({ target: { id, value } }) =>
            handleChangeBusinessInformation(id, value)
          }
        ></textarea>
      </form>

      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => {
            if (clientData?.uuid) {
              navigate('/Clients')
              return
            }
            setStep(STEPS.STEP_REGISTRATION_DATA)
          }}
          variant={true}
        >
          {t('step_business_information.button_back')}
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => onSubmitBusiness()} variant={'next'}>
          {t('step_business_information.button_next')}
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepBusinessInformation

StepBusinessInformation.propTypes = {
  setStep: PropTypes.func,
}
