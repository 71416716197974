import { useState } from 'react'

import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'

import { motion } from 'framer-motion'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ForgotPasswordService } from '../../../services'

function StepEmail({ setEmail, email, setStep }) {
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const STEPS = {
    STEP_VERIFY_EMAIL: 'stepVerifyEmail',
  }

  const isValidEmail = (email) => {
    // Expressão regular para validar e-mail
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    // Testa o e-mail recebido com o regex
    return emailRegex.test(email)
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (email === '') {
        notifyErrorCustom(t('fast_publishing_email_enter_your_email_alert'))
        setIsLoading(false)
        return
      } else if (!isValidEmail(email)) {
        notifyErrorCustom(t('fast_publishing_email_invalid_format'))
        setIsLoading(false)
        return
      }

      const response = await ForgotPasswordService.postsendCode({
        email,
      })

      console.log('response', response)

      setIsLoading(false)

      if (response.responseStatus === 200) {
        setStep(STEPS.STEP_VERIFY_EMAIL)
      } else {
        notifyErrorCustom(t('forgotPasswordStepPassword.forgot_password_error'))
      }
    } else {
      navigate('/')
    }
  }

  const { t } = useTranslation()
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div>{/* <PreviousPass steps={stepsArray}/> */}</div>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>
            {t('forgotPassword.forgot_password_title')}
          </p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
            {t('forgotPassword.forgot_password_subtitle')}
          </h1>

          <div className={styles.box_input_email}>
            <p>{t('forgotPassword.forgot_password_subtitle_description')}</p>
            <input
              className={styles.input_email}
              type="text"
              value={email}
              placeholder={t(
                'forgotPassword.forgot_password_email_placeholder'
              )}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            {t('forgotPassword.back_button')}
          </button>
          <button
            disabled={isLoading}
            className={styles.next_button}
            onClick={() => {
              setIsLoading(true)
              handleNavigate('next')
            }}
          >
            {t('forgotPassword.next_button')}
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepEmail
