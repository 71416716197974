import { useState } from 'react'
import styles from './styles.module.css'
import { Eye, Interrogation } from '../../../assets/svgs/icons'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import { motion } from 'framer-motion'

import { ForgotPasswordService } from '../../../services'

// import { UserService } from '../../../services'
// import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
function StepPassword({
  setPass,
  setConfirmPass,
  pass,
  confirmPass,
  setStep,
  email,
  code,
}) {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [showPassConfirm, setShowPassConfirm] = useState(false)

  const handlePass = (type) => {
    if (type === 'pass') {
      setShowPass(!showPass)
    } else {
      setShowPassConfirm(!showPassConfirm)
    }
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (pass === '' || confirmPass === '') {
        notifyErrorCustom(t('fast_publishing_pass_enter_your_password_alert'))
        return
      }
      if (pass.length < 9 || confirmPass.length < 9) {
        notifyErrorCustom(t('fast_publishing_pass_short_password_alert'))
        return
      }
      if (pass != confirmPass) {
        notifyErrorCustom(t('fast_publishing_pass_confirmation_password_alert'))
        return
      }

      const response = await ForgotPasswordService.postResetPassword({
        email,
        code,
        newPassword: pass,
      })

      if (response.responseStatus === 200) {
        navigate('/')
      } else {
        notifyErrorCustom(t('forgotPasswordStepPassword.forgot_password_error'))
      }

      console.log('response', response)
    }
  }

  const { t } = useTranslation()

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>
            {t('forgotPasswordStepPassword.forgot_password_password_title')}
          </p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
            {t('forgotPasswordStepPassword.forgot_password_password_subtitle')}
          </h1>

          <div className={styles.box_input_password}>
            <p>
              {t(
                'forgotPasswordStepPassword.forgot_password_password_subtitle_description'
              )}
            </p>
            <div className={styles.container_input_password}>
              <div
                className={styles.custom_input}
                type="text"
                placeholder="Senha"
              >
                <input
                  className={styles.input_password}
                  type={showPass ? 'text' : 'password'}
                  placeholder="Senha"
                  value={pass}
                  onChange={(e) => setPass(e.currentTarget.value)}
                />
                <div
                  className={styles.icon_input}
                  onClick={() => handlePass('pass')}
                >
                  <Eye />
                </div>
              </div>

              <div
                className={styles.custom_input}
                type="text"
                placeholder="Senha"
              >
                <input
                  className={styles.input_password}
                  type={showPassConfirm ? 'text' : 'password'}
                  placeholder="Confirmar senha"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.currentTarget.value)}
                />
                <div
                  className={styles.icon_input}
                  onClick={() => handlePass('passConfirm')}
                >
                  <Eye />
                </div>
              </div>
            </div>

            <div className={styles.box_information}>
              <Interrogation color={'#FF8B00'} width={20} height={20} />
              <p>{t('forgotPasswordStepPassword.fast_publishing_pass_tip')}</p>
            </div>
          </div>
        </div>
        <div className={styles.box_button}>
          <div />

          <button
            disabled={isLoading}
            className={styles.next_button}
            onClick={() => {
              setIsLoading(true)
              handleNavigate('next')
            }}
          >
            {t('forgotPasswordStepPassword.next_button')}
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepPassword
