import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import InputMask from 'react-input-mask'

import { motion } from 'framer-motion'
import { UserAccountsService, UserService } from '../../../../services'
import {
  notifyErrorCustom,
  notifySuccessCustom,
} from '../../../../utils/notify'
import * as yup from 'yup'
import { PencilUploadIcon } from '../../../../assets/svgs/icons'
import { Empty } from 'antd'

import { Loading } from '../../../../components/Loading'
import { getAllLocationStatesService } from '../../../../services/locationsService'
import { SelectSquare } from '../../../../components/Select/SelectSquare/SelectSquare'
import { CustomSelect } from '../../../../components/Select/CustomSelect'
import { useTranslation } from 'react-i18next'

export const MyAccountStep = () => {
  const { t } = useTranslation()

  const [userData, setUserData] = useState({})

  const [userImageData, setUserImageData] = useState([])
  const [imagesToRender, setImagesToRender] = useState({})

  const [loading, setLoading] = useState(false)

  const [locationsStatesOptions, setLocationsStatesOptions] = useState([
    { label: '', value: '' },
  ])

  useEffect(() => {
    loadDataUser()
    loadDropdownsData()
  }, [])

  const loadDropdownsData = async () => {
    // faça chamada dos serviços
    const { data: stateLocationData, responseStatus: stateLocationStatus } =
      await getAllLocationStatesService()

    if (stateLocationStatus === 200)
      setLocationsStatesOptions(
        stateLocationData.map((e) => ({ label: e.name, value: e.id }))
      )
  }

  const loadDataUser = async () => {
    setLoading(true)
    const { data: dataAccount, responseStatus: responseStatusAccount } =
      await UserAccountsService.getUserAccountsByLogin()
    setLoading(false)

    if (responseStatusAccount === 200 || responseStatusAccount === 204) {
      setImagesToRender(dataAccount)
    } else {
      console.log(responseStatusAccount)
    }

    setLoading(true)

    const { responseStatus, data } = await UserService.getUserByLogin()
    setLoading(false)

    if (responseStatus === 200 || responseStatus === 204) {
      setUserData(data)
    }
  }

  const registrationDataScheema = yup.object().shape({
    name: yup.string().required(t('my_account_step.validation.name')),
    surname: yup.string().required(t('my_account_step.validation.surname')),
    email: yup
      .string()
      .email(t('my_account_step.validation.email'))
      .required(t('my_account_step.validation.email')),
    phone: yup.string().required(t('my_account_step.validation.phone')),
  })

  const validateFields = async () => {
    const isValid = await registrationDataScheema
      .validate(userData)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })

    return isValid
  }

  const handleChange = (k, v) => {
    setUserData((prevState) => ({ ...prevState, [k]: v }))
  }

  const handleChangeAdress = (k, v) => {
    setImagesToRender((prevState) => ({ ...prevState, [k]: v }))
  }

  const UpdateUserInfo = async (e) => {
    e.preventDefault()
    const isValid = await validateFields()

    if (!isValid) {
      return
    }

    if (userImageData[0]?.isBase64 && userImageData[0]?.status != 'deleted') {
      const uploadImageBody = mountImageBodyUpload(
        userImageData[0]?.fileUrl,
        userImageData[0]?.fileName,
        userImageData[0]?.fileFormat,
        userImageData[0].fileContentType
      )

      if (imagesToRender?.photoName === '') {
        setLoading(true)

        const { dataImage, responseImageStatus } =
          await UserAccountsService.postAccountsImage(uploadImageBody)
        setLoading(false)

        if (responseImageStatus === 200 || responseImageStatus === 204) {
          console.log(responseImageStatus)
        }

        let bodyUserAccounts = {
          userId: userData?.uuid,
          photoName: dataImage?.fileName,
          photoUrl: dataImage?.fileUrl,
        }

        setLoading(true)

        const { responseStatus } =
          await UserAccountsService.putUserAccountsByLogin(bodyUserAccounts)
        setLoading(false)

        if (responseStatus === 200 || responseStatus === 204) {
          notifySuccessCustom(
            t('my_account_step.notifications.success.user_updated')
          )
        } else {
          notifyErrorCustom(
            t('my_account_step.notifications.error.user_update')
          )
          return
        }
      } else {
        const { responseStatusImage } =
          UserAccountsService.deleteUserAccountsImage(imagesToRender?.photoName)

        if (responseStatusImage === 200 || responseStatusImage === 204) {
          console.log(responseStatusImage)
        } else {
          console.log(responseStatusImage)
          // notifyErrorCustom('Não foi possivel alterar o usuario!')
        }
        setLoading(true)

        //

        const { dataImage, responseImageStatus } =
          await UserAccountsService.postAccountsImage(uploadImageBody)
        setLoading(false)

        if (responseImageStatus === 200 || responseImageStatus === 204) {
          console.log(responseImageStatus)
        }
        let bodyUserAccounts = {
          userId: userData?.uuid,
          photoName: dataImage?.fileName,
          photoUrl: dataImage?.fileUrl,
        }

        setLoading(true)

        const { responseStatus } =
          await UserAccountsService.putUserAccountsByLogin(bodyUserAccounts)
        setLoading(false)

        if (responseStatus === 200 || responseStatus === 204) {
          // notifySuccessCustom('Usuario alterado com sucesso', '')
          console.log(responseStatus)
        } else {
          notifyErrorCustom('Não foi possivel alterar o usuario!')
          return
        }
      }
      delete userData.uuid
      delete userData.createdAt
      delete userData.updatedAt
      delete userData.deletedAt
      setLoading(true)

      const { responseStatus } = await UserService.updateUserById(userData)
      setLoading(false)

      if (responseStatus === 200 || responseStatus === 204) {
        notifySuccessCustom('Usuario alterado com sucesso', '')
      } else {
        notifyErrorCustom('Não foi possível alterar os dados do usuario!')
      }
    } else {
      delete userData.uuid
      delete userData.createdAt
      delete userData.updatedAt
      delete userData.deletedAt

      setLoading(true)
      const { responseStatus } = await UserService.updateUserById(userData)

      setLoading(false)

      if (responseStatus === 200 || responseStatus === 204) {
        notifySuccessCustom('Usuario alterado com sucesso', '')
      } else {
        notifyErrorCustom('Não foi possível alterar os dados do usuario!')
      }

      delete imagesToRender.uuid
      delete imagesToRender.createdAt
      delete imagesToRender.updatedAt
      delete imagesToRender.deletedAt
      delete imagesToRender.PaymentHistory

      const { responseStatus: responseAccount } =
        await UserAccountsService.putUserAccountsByLogin(imagesToRender)

      if (responseAccount === 200 || responseAccount === 204) {
        //notifySuccessCustom('Endereço alterado com sucesso', '')
      } else {
        notifyErrorCustom(
          t('my_account_step.notifications.error.address_update')
        )
      }
    }
    loadDataUser()
  }

  const mountImageBodyUpload = (base64Str, name, type) => {
    const formats = ['jpg', 'png', 'jpeg', 'webp']
    const stringReference = 'base64,'
    const index = base64Str.indexOf(stringReference) + stringReference.length
    let fileFormat = ''
    const fileBase64 = base64Str.substr(index)
    formats.map((format) => {
      if (base64Str.includes(format)) fileFormat = format
    })

    return {
      fileName: name,
      fileFormat,
      fileContentType: type,
      fileBase64,
    }
  }

  const onFileChange = (e) => {
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result
        let fileDataReturn = {
          fileUrl: fileDataBase64,
          isBase64: true,
          status: 'create',
          fileName: file.name,
          fileFormat: file.type,
          fileContentType: 'image/' + file.type,
        }

        setUserImageData(() => [fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{ display: 'flex', width: '100%' }}
      >
        <div className={styles.container_my_account_step}>
          <form action="">
            <div className={styles.module}>
              <h3 className={styles.module_title}>
                {' '}
                {t('my_account.steps.my_account')}
              </h3>
              <div className={styles.my_account_module}>
                <div className={styles.box_company}>
                  <div className={styles.box_image_user_upload}>
                    <img
                      className={styles.image_user_upload}
                      src={
                        userImageData[0] !== undefined
                          ? userImageData[0]?.fileUrl
                          : imagesToRender?.photoUrl
                      }
                      alt=""
                    />
                    <div className={styles.button_user_upload}>
                      <label htmlFor="arquivo">
                        <PencilUploadIcon
                          color={'#FF8B00'}
                          colorPen={''}
                          width={30}
                          height={30}
                        />
                      </label>
                      <input
                        type="file"
                        name="arquivo"
                        id="arquivo"
                        onChange={(e) => onFileChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p className={styles.label_company}>
                      {userData?.name + ' ' + userData?.surname}
                    </p>
                    {/* <p className={styles.title_company}>@nomedaempresa</p> */}
                  </div>
                </div>
                <div className={styles.container_input_form}>
                  <div className={styles.container_line_input_form}>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.name')}
                      </label>
                      <input
                        className={styles.input_form}
                        type="text"
                        id="name"
                        value={userData?.name}
                        placeholder="Nome do usuario"
                        onChange={({ target: { id, value } }) =>
                          handleChange(id, value)
                        }
                      />
                    </div>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.surname')}
                      </label>
                      <input
                        className={styles.input_form}
                        type="text"
                        id="surname"
                        value={userData?.surname}
                        placeholder="Sobrenome"
                        onChange={({ target: { id, value } }) =>
                          handleChange(id, value)
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.container_line_input_form}>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.email')}
                      </label>
                      <input
                        className={styles.input_form}
                        type="text"
                        id="email"
                        value={userData?.email}
                        placeholder="exemplo@exemplo.com"
                        onChange={({ target: { id, value } }) =>
                          handleChange(id, value)
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.container_line_input_form}>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.phone')}
                      </label>
                      <InputMask
                        className={styles.input_form}
                        type="text"
                        mask="(99) 99999-9999"
                        placeholder="(00) 00000-0000"
                        id="phone"
                        value={userData?.phone}
                        onChange={({ target: { id, value } }) =>
                          handleChange(id, value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.my_address_module}>
                <div>
                  <h3 className={styles.module_title}>
                    {t('my_account_step.form.address')}
                  </h3>
                </div>
                <div className={styles.container_input_form}>
                  <div className={styles.container_line_input_form}>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.cep')}
                      </label>
                      <InputMask
                        className={styles.input_form}
                        type="text"
                        mask="99999-999"
                        placeholder="00000-000"
                        id="cep"
                        value={imagesToRender?.cep}
                        onChange={({ target: { id, value } }) =>
                          handleChangeAdress(id, value)
                        }
                      />
                    </div>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.street')}
                      </label>
                      <input
                        id="logradouro"
                        className={styles.input_form}
                        type="text"
                        placeholder="Digite seu endereço"
                        value={imagesToRender.logradouro}
                        onChange={({ target: { id, value } }) =>
                          handleChangeAdress(id, value)
                        }
                      />
                    </div>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.number')}
                      </label>
                      <input
                        id="number"
                        className={styles.input_form}
                        type="text"
                        placeholder="000"
                        value={imagesToRender.number}
                        onChange={({ target: { id, value } }) =>
                          handleChangeAdress(id, value)
                        }
                      />
                    </div>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.complement')}
                      </label>
                      <input
                        id="complement"
                        className={styles.input_form}
                        type="text"
                        placeholder="Casa / Apto"
                        value={imagesToRender.complement}
                        onChange={({ target: { id, value } }) =>
                          handleChangeAdress(id, value)
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.container_line_input_form}>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.city')}
                      </label>
                      <input
                        id="city"
                        className={styles.input_form}
                        type="text"
                        placeholder="Digite sua cidede"
                        value={imagesToRender.city}
                        onChange={({ target: { id, value } }) =>
                          handleChangeAdress(id, value)
                        }
                      />
                    </div>
                    <div className={styles.box_input_form}>
                      <label className={styles.label_form}>
                        {t('my_account_step.form.state')}
                      </label>
                      {/* <input
                        id="state"
                        className={styles.input_form}
                        type="text"
                        placeholder="Digite seu estado"
                        value={imagesToRender.state}
                        onChange={({ target: { id, value } }) =>
                          handleChangeAdress(id, parseInt(value))
                        }
                      /> */}

                      <SelectSquare
                        backgroundColor={'#fff'}
                        border={true}
                        borderColor={'#dadada'}
                        placeholder={'Selecione'}
                        arrowDirection="down"
                        options={locationsStatesOptions}
                        fontSize={12}
                        defaultSelected={locationsStatesOptions.find(
                          (e) => e.value === imagesToRender?.state
                        )}
                        onClickItem={({ value }) =>
                          handleChangeAdress('state', value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <button
                className={styles.button_save}
                onClick={(e) => UpdateUserInfo(e)}
              >
                {t('my_account_step.button.save')}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </>
  )
}
