import { Heart } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function InputIA({
  placeholder,
  variant,
  clickFunction,
  setLoading,
  text,
  setText,
  suggestions = true,
}) {
  // const [suggestion, setSuggestions] = useState('')
  const { t } = useTranslation()
  const handleClick = async () => {
    setLoading(true)
    await clickFunction()
    setLoading(false)
  }

  const handleChange = (event) => {
    setText(event.target.value)
  }

  // const handleChange = (k, v) => {
  //   setBrandingArchetypeData((prevState) => ({ ...prevState, [k]: v }))
  // }

  return (
    <div className={styles.input_box}>
      <input
        className={styles.input}
        type="text"
        // defaultValue={text}
        value={text}
        onChange={handleChange}
        // onChange={({ target: { id, value } }) => handleChange(id, value)}
        placeholder={placeholder}
      />
      {suggestions && (
        <div
          className={
            variant === 'light'
              ? styles.button_suggestions_light
              : styles.button_suggestions
          }
          onClick={handleClick}
        >
          <Heart color={variant === 'light' ? '#6A0098' : '#E6E6E6'} />
          <p
            className={
              variant === 'light'
                ? styles.button_suggestions_title_light
                : styles.button_suggestions_title
            }
          >
            {t('ai-suggestions')}
          </p>
        </div>
      )}
    </div>
  )
}

export default InputIA
