import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { TimelineActionsService } from '../../services'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Modal = ({ onClose }) => {
  const modalRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isClosing, setIsClosing] = useState(false)
  const [timelineActionData, setTimelineActionData] = useState([])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const storage = window.localStorage

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal()
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [onClose])

  useEffect(() => {
    setIsOpen(true)

    handleLoadTimeline()
    return () => {
      setIsOpen(false)
    }
  }, [])

  const closeModal = () => {
    setIsClosing(true)
    setTimeout(() => {
      onClose()
    }, 300)
  }

  const handleClickSeeAll = async () => {
    navigate('/TimeLinePage')
    closeModal()
  }

  const handleLoadTimeline = async () => {
    const { data, responseStatus } =
      await TimelineActionsService.getTimelineActionAllData(
        storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR',
        1,
        9
      )

    setTimelineActionData(
      data?.map((item) => ({
        userName: item.UserAccount.user.name,
        clientName: item.BusinessClient?.companyName,
        timelineAction: item.timelineActionsValue,
        date: formatDate(item.createdAt),
        guestName: item.UserAccountBusinessUsers
          ? item.UserAccountBusinessUsers.name
          : null,
      }))
    )
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = addLeadingZero(date.getDate())
    const month = addLeadingZero(date.getMonth() + 1)
    const year = date.getFullYear()
    const hours = addLeadingZero(date.getHours())
    const minutes = addLeadingZero(date.getMinutes())
    const secconds = addLeadingZero(date.getSeconds())
    return `${day}/${month}/${year} ${hours}:${minutes}:${secconds}`
  }
  const addLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value
  }

  return (
    <>
      <div
        className={`${styles.modalContent} ${isOpen ? styles.open : ''}  ${
          isClosing ? styles.close : ''
        }`}
        ref={modalRef}
      >
        <div className={styles.headerNotify}>
          <h2>{t('my_account.steps.notifications')}:</h2>
          <h3>{timelineActionData.length}</h3>
        </div>
        {timelineActionData.map((item, index) => (
          <div key={item.uuid} className={styles.bodyNotify}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M4.21444 3.72682C4.47824 3.40747 4.43321 2.93474 4.11386 2.67094C3.79452 2.40714 3.32178 2.45217 3.05798 2.77151C2.1587 3.86014 1.50992 5.16493 1.20153 6.59703C1.11433 7.00196 1.37191 7.40091 1.77684 7.48811C2.18177 7.57531 2.58072 7.31774 2.66792 6.91281C2.92443 5.72161 3.46427 4.63494 4.21444 3.72682Z"
                fill="#B900FE"
              />
              <path
                d="M16.9417 2.77151C16.6779 2.45217 16.2051 2.40714 15.8858 2.67094C15.5664 2.93474 15.5214 3.40747 15.7852 3.72682C16.5354 4.63494 17.0752 5.72161 17.3317 6.91281C17.4189 7.31774 17.8179 7.57531 18.2228 7.48811C18.6277 7.40091 18.8853 7.00196 18.7981 6.59703C18.4897 5.16493 17.8409 3.86014 16.9417 2.77151Z"
                fill="#B900FE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99997 2.5C6.68626 2.5 3.99997 5.18629 3.99997 8.5C3.99997 10.3866 3.54624 12.1651 2.7426 13.7343C2.63591 13.9426 2.6326 14.1888 2.73365 14.4C2.83469 14.6111 3.02851 14.7629 3.25769 14.8105C4.32537 15.0322 5.41181 15.2023 6.51426 15.318C6.67494 17.102 8.17421 18.5 10 18.5C11.8258 18.5 13.3251 17.102 13.4857 15.318C14.5882 15.2023 15.6746 15.0322 16.7422 14.8105C16.9714 14.7629 17.1652 14.6111 17.2663 14.4C17.3673 14.1888 17.364 13.9426 17.2573 13.7343C16.4537 12.1651 16 10.3866 16 8.5C16 5.18629 13.3137 2.5 9.99997 2.5ZM10 17C9.04777 17 8.25097 16.3345 8.0493 15.4433C8.69477 15.4809 9.34517 15.5 9.99997 15.5C10.6548 15.5 11.3052 15.4809 11.9507 15.4433C11.749 16.3345 10.9522 17 10 17Z"
                fill="#B900FE"
              />
            </svg>
            <div className={styles.textNotify}>
              {item.guestName ? (
                <div>
                  <h1>
                    {t('notifications.guest_user')}{' '}
                    <span>{item.guestName} </span>
                    {item.timelineAction}{' '}
                    {item.clientName ? 'para o cliente ' : ' '}
                    <span>{item.clientName}</span>
                  </h1>
                  <p>{item.date}</p>
                </div>
              ) : (
                <div>
                  <h1>
                    {t('notifications.user')} <span>{item.userName} </span>
                    {item.timelineAction}{' '}
                    {item.clientName ? 'para o cliente ' : ' '}
                    <span>{item.clientName}</span>
                  </h1>
                  <p>{item.date}</p>
                </div>
              )}
              {/* <div>
                <h1>{item.titulo}</h1>
              </div>
              <div>
                <p>{item.mensagem}</p>
              </div> */}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="7"
              viewBox="0 0 6 7"
              fill="none"
            >
              <circle cx="3" cy="3.5" r="3" fill="#FF8B00" />
            </svg>
          </div>
        ))}
        <div className={styles.allNotifyBtn}>
          <button onClick={handleClickSeeAll}>
            <p>{t('my_account.steps.sell_all_notifications')}</p>
          </button>
        </div>
      </div>
    </>
  )
}

export default Modal
