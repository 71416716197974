import React, { useState } from 'react'
import styles from './styles.module.css'
import {
  NavigateArrowLeft,
  // NavigateArrowRigth,
} from '../../../assets/svgs/icons'
import { useTranslation } from 'react-i18next'

export const SchedulerCalendarMini = () => {
  const { t } = useTranslation()
  const selectedDate = new Date().toDateString()

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  const [currentDate, setCurrentDate] = useState(new Date()) // Get the current date
  const [selectedDates, setSelectedDates] = useState([]) // Store selected dates
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

  const today = new Date()

  const renderDays = () => {
    const days = []

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day)

      today.setHours(0, 0, 0, 0)
      date.setHours(0, 0, 0, 0)

      const isPastDate = date < today

      const isCurrentDate = date.getTime() === today.getTime()

      const isSelected = date.toDateString() == selectedDate

      days.push(
        <div
          className={`${styles.day_box} ${
            isCurrentDate ? styles.current_day : isPastDate ? '' : ''
          }`}
          key={day}
          onClick={() => {
            if (!isPastDate) {
              handleDateClick(date)
            }
          }}
        >
          <div
            className={` ${styles.day} ${isSelected ? styles.selected : ''}`}
          >
            {day}
          </div>
        </div>
      )
    }

    return days
  }

  function handleNextMonth() {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear(currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)
    }
  }

  function handlePreviusMonth() {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)
    }
  }

  function handleDateClick(date) {
    if (date >= today) {
      const isSelected = selectedDates.some(
        (selectedDate) => selectedDate.getTime() === date.getTime()
      )

      if (isSelected) {
        setSelectedDates(
          selectedDates.filter(
            (selectedDate) => selectedDate.getTime() !== date.getTime()
          )
        )
      } else {
        setSelectedDates([...selectedDates, date])
      }
    }
  }

  const findDate = (mes, dia, ano) => {
    let date = mes + '-' + dia + '-' + ano
    let verifyDate = datesData.find((dateData) => dateData === date)

    if (verifyDate) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={styles.calendar_wrapper}>
      <div className={styles.calendar_header}>
        <h3>{monthNames[currentMonth]}</h3>
        <div className={styles.box_calendar_button_navigate}>
          <div
            className={styles.calendar_button_navigate}
            onClick={handlePreviusMonth}
          >
            <NavigateArrowLeft width={8} height={8} />
          </div>
          <div
            className={styles.calendar_button_navigate_left}
            onClick={handleNextMonth}
          >
            <NavigateArrowLeft width={8} height={8} />
          </div>
        </div>
      </div>

      <div className={styles.calendar}>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>{t('schedulerCalendar.sunday')}</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>{t('schedulerCalendar.monday')}</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>{t('schedulerCalendar.tuesday')}</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>{t('schedulerCalendar.wednesday')}</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>{t('schedulerCalendar.thursday')}</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>{t('schedulerCalendar.friday')}</b>
        </div>
        <div className={styles.day_name}>
          <b className={styles.day_name_letter}>{t('schedulerCalendar.saturday')}</b>
        </div>

        {new Array(new Date(currentYear, currentMonth, 1).getDay())
          .fill(null)
          .map((_, index) => (
            <div
              key={`empty-${index}`}
              className={`${styles.empty_day} ${styles.disabled} `}
            ></div>
          ))}

        {renderDays()}

        {new Array(
          6 - new Date(currentYear, currentMonth, daysInMonth).getDay()
        )
          .fill(null)
          .map((_, index) => (
            <div
              key={`empty-${daysInMonth + index}`}
              className={`${styles.empty_day} ${styles.disabled} `}
            ></div>
          ))}
      </div>
    </div>
  )
}

export default SchedulerCalendarMini
