/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import { Select } from '../../../Select/LightSelect'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

import { ProductService } from '../../../../services'
import { CampaignService } from '../../../../services'
import { TargetAudienceService } from '../../../../services'

export const CampaingStep = ({
  onChangeItems,
  targetPublicOptionSelected,
  productOptionSelected,
  campaingOptionSelected,
  clientData,
  isCampaign,
}) => {
  const [campaingOptions, setCampaingOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [targetPublicOptions, setTargetPublicOptions] = useState([])
  const storage = window.localStorage

  useEffect(() => {
    if (isCampaign) {
      loadProductServiceData()
      return
    } else {
      loadProductServiceData()
    }

    loadtargetAudienceData()
    loadCampaingServiceData()
  }, [])

  const loadtargetAudienceData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByNotClientId(
          storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR'
        )
      if (responseStatus === 200)
        setTargetPublicOptions(
          data?.map((item) => ({
            label: item.profession,
            value: item.uuid,
          }))
        )
    } else {
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByClientId(
          clientData?.uuid
        )
      if (responseStatus === 200)
        setTargetPublicOptions(
          data?.map((item) => ({
            label: item.profession,
            value: item.uuid,
          }))
        )
    }
  }

  const loadProductServiceData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await ProductService.getProductByNotBusinessClient()

      if (responseStatus === 200) {
        setProductOptions(
          data.map((item) => ({
            label: item.name,
            value: item.uuid,
          }))
        )
      }
    } else {
      const { data, responseStatus } =
        await ProductService.getProductByBusinessClientId(clientData?.uuid)

      if (responseStatus === 200) {
        setProductOptions(
          data.map((item) => ({
            label: item.name,
            value: item.uuid,
          }))
        )
      }
    }
  }

  const loadCampaingServiceData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await CampaignService.getCampaignByNotClient()

      if (responseStatus === 200) {
        setCampaingOptions(
          data.map((item) => ({
            label: item.name,
            value: item.uuid,
          }))
        )
      }
    } else {
      const { data, responseStatus } =
        await CampaignService.getCampaignByClient(clientData?.uuid)

      if (responseStatus === 200) {
        setCampaingOptions(
          data.map((item) => ({
            label: item.name,
            value: item.uuid,
          }))
        )
      }
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container}
    >
      {!isCampaign && (
        <Select
          label={'Campanha'}
          options={campaingOptions}
          placeholder={'Selecione'}
          defaultSelected={campaingOptionSelected}
          onSelectItem={(e) => onChangeItems(e, 'campaing')}
        />
      )}

      <Select
        label={'Produto'}
        options={productOptions}
        placeholder={'Selecione'}
        defaultSelected={productOptionSelected}
        onSelectItem={(e) => onChangeItems(e, 'product')}
      />

      {!isCampaign && (
        <Select
          label={'Publico-Alvo'}
          options={targetPublicOptions}
          placeholder={'Selecione'}
          defaultSelected={targetPublicOptionSelected}
          onSelectItem={(e) => onChangeItems(e, 'targetPublic')}
        />
      )}
    </motion.div>
  )
}

CampaingStep.propTypes = {
  campaingOptions: PropTypes.array,
  productOptions: PropTypes.array,
  targetPublicOptions: PropTypes.array,
  campaingOptionSelected: PropTypes.object,
  productOptionSelected: PropTypes.object,
  targetPublicOptionSelected: PropTypes.object,
  onChangeItems: PropTypes.func,
}
