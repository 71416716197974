import {
  HeartLike,
  PlaneShare,
  PreviewArrowRight,
  Trash,
} from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { object } from 'prop-types'
import { useState } from 'react'

export const LayoutInstagramStories = ({
  image,
  legend,
  userData,
  setValueItem,
  type,
  selectedValues,
  setImageUrl,
  setTiktokMusic,
  setTiktokMusicCheckbox,
  removeImage
}) => {
  const [showButtons, setShowButtons] = useState(false)

  function nextImage(type) {
    if (type === 4) {
      setValueItem(1)
    } else {
      setValueItem(type + 1)
    }
  }

  function previewImage(type) {
    if (type === 1) {
      setValueItem(4)
    } else {
      setValueItem(type - 1)
    }
  }
  return (
    <div className={styles.box_image_render}>
      <div className={styles.body_image_render}>
        <p>Stories</p>

        <div
          onMouseEnter={() => setShowButtons(true)}
          onMouseLeave={() => setShowButtons(false)}
          className={styles.box_body_image_render}
        >
          <div>
          <img src={image} className={`${styles.image_render_stories} ${removeImage ? styles.blur_image: ''}`} />

            <div className={styles.box_buttons}>
              <button
                className={styles.carouselButtonLeft}
                style={{
                  transform: 'rotate(180deg)',
                  outline: 'none',
                  border: 'none',
                  opacity: showButtons ? 1 : 0,
                  transition: 'opacity 0.3s',
                }}
                onClick={() => nextImage(type)}
              >
                <div style={{ marginBottom: -30,
                  display: 'flex',
                  background: '#000',
                  borderRadius: '5px'
                }}>
                  <PreviewArrowRight />
                </div>
              </button>

              {removeImage &&(
                <div className={styles.box_button_delete_image}>
                  <button
                    onClick={() => {
                      setImageUrl()
                      setTiktokMusic()
                      setTiktokMusicCheckbox()
                    }}
                    className={styles.button_delete_image}
                    style={{
                      opacity: showButtons ? 1 : 0,
                      transition: 'opacity 0.3s',
                    }}
                  ><Trash width={25} height={25} color={"#000000"}/></button>
                </div>
              )}
              <button
                className={styles.carouselButtonRight}
                style={{
                  outline: 'none',
                  border: 'none',
                  opacity: showButtons ? 1 : 0,
                  transition: 'opacity 0.3s',
                }}
                onClick={() => previewImage(type)}
              >
                <div style={{ display: 'flex',
                background: '#000',
                borderRadius: '5px'}}>
                  <PreviewArrowRight />
                </div>
              </button>
            </div>
          </div>

          <div className={styles.teste}>
            <div className={styles.box_input_example}>
              <div className={styles.input_example}>
                <p className={styles.input_example_text}>
                  Responder a Minha conta{' '}
                </p>
              </div>
              <div className={styles.input_example_icons}>
                <HeartLike color={'#FFFFFF'} />
                <PlaneShare color={'#FFFFFF'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LayoutInstagramStories.propTypes = {
  image: object,
}
