/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'

import { useEffect, useState, useRef, useCallback } from 'react'

// import { StarIcon } from '../../../assets/svgs/icons'
import starAnimated from '../../../assets/lottie/star.json'
import Lottie from 'lottie-react'

import { PublicationService } from '../../../services'
import { useTranslation } from 'react-i18next'

function StepPublicationSuccess({ imageUrl, publicationIdRef }) {
  const { t } = useTranslation()
  const navigation = useNavigate()

  const ratingRef = useRef(undefined)

  useEffect(() => {
    console.log('imageUrl', imageUrl)
    console.log('publicationIdRef', publicationIdRef.current)
  }, [])

  const publicationSuccessFinish = async (route) => {
    if (publicationIdRef.current && ratingRef.current) {
      console.log('publicationIdRef', publicationIdRef.current)

      let publicationBody = publicationIdRef.current.map((item) => {
        return {
          uuid: item?.uuid,
          classification: ratingRef.current,
        }
      })

      const response = await PublicationService.putPublicationClassification(
        publicationBody
      )
    }

    console.log('25', route)
    navigation(route)
  }

  const StarRating = () => {
    const [rating, setRating] = useState(0)

    const lottieRefs1 = useRef()
    const lottieRefs2 = useRef()
    const lottieRefs3 = useRef()
    const lottieRefs4 = useRef()
    const lottieRefs5 = useRef()

    const handleClick = (index) => {
      setRating(index + 1)
    }

    useEffect(() => {
      if (rating === 1) {
        ratingRef.current = 1

        lottieRefs1.current.goToAndPlay(29, true)

        lottieRefs2.current.stop()
        lottieRefs3.current.stop()
        lottieRefs4.current.stop()
        lottieRefs5.current.stop()
      } else if (rating === 2) {
        ratingRef.current = 2

        lottieRefs1.current.goToAndPlay(29, true)
        lottieRefs2.current.goToAndPlay(29, true)

        lottieRefs3.current.stop()
        lottieRefs4.current.stop()
        lottieRefs5.current.stop()
      } else if (rating === 3) {
        ratingRef.current = 3

        lottieRefs1.current.goToAndPlay(29, true)
        lottieRefs2.current.goToAndPlay(29, true)
        lottieRefs3.current.goToAndPlay(29, true)

        lottieRefs4.current.stop()
        lottieRefs5.current.stop()
      } else if (rating === 4) {
        ratingRef.current = 4

        lottieRefs1.current.goToAndPlay(29, true)
        lottieRefs2.current.goToAndPlay(29, true)
        lottieRefs3.current.goToAndPlay(29, true)
        lottieRefs4.current.goToAndPlay(29, true)

        lottieRefs5.current.stop()
      } else if (rating === 5) {
        ratingRef.current = 5

        lottieRefs1.current.goToAndPlay(29, true)
        lottieRefs2.current.goToAndPlay(29, true)
        lottieRefs3.current.goToAndPlay(29, true)
        lottieRefs4.current.goToAndPlay(29, true)
        lottieRefs5.current.goToAndPlay(29, true)
      }
    }, [rating])

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          // gap: 8, // Espaçamento entre as estrelas
        }}
      >
        {[0, 1, 2, 3, 4].map((index) => (
          <div
            style={{
              width: 40,
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              position: 'relative',
            }}
            key={index}
            onClick={() => handleClick(index)}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-48%, -46%) scale(1.8)', // Aumenta a animação sem aumentar a área clicável
              }}
            >
              <Lottie
                lottieRef={
                  index === 0
                    ? lottieRefs1
                    : index === 1
                    ? lottieRefs2
                    : index === 2
                    ? lottieRefs3
                    : index === 3
                    ? lottieRefs4
                    : lottieRefs5
                }
                animationData={starAnimated}
                style={{ width: 50, height: 50 }}
                loop={false}
                autoplay={false}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }

  const ContentRender = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',

          gap: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontSize: 26,
              fontFamily: 'Poppins',
              fontWeight: 500,
            }}
          >
            {t(
              'new_publication_page_campaign_step_publication_success_congratulations'
            )}
          </div>

          <div
            style={{
              fontSize: 26,
              fontFamily: 'Poppins',
              fontWeight: 700,
            }}
          >
            {t(
              'new_publication_page_campaign_step_publication_success_publication_scheduled_success'
            )}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            gap: 20,
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {imageUrl.map((item, index) => {
            return (
              <img
                key={index}
                src={item?.image}
                style={{
                  width: 176,
                  height: 220,
                  objectFit: 'contain',
                  borderRadius: 10,

                  boxShadow: '14.7px 14.7px 29.4px 0px #00000026',
                }}
              />
            )
          })}
        </div>

        <StarRating />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <div
            style={{
              border: '1px solid #6A0098',
              padding: '6px 20px',
              borderRadius: 20,
              cursor: 'pointer',

              userSelect: 'none',

              fontSize: 16,
              fontFamily: 'Poppins',
              fontWeight: 500,
              color: '#6A0098',
            }}
            onClick={() => {
              // navigation('/NewPostPage')
              // publicationSuccessFinish('/NewPublicationPageCampaign')
              //publicationSuccessFinish('/NewPostPage')
              window.location.reload()
            }}
          >
            {t(
              'new_publication_page_campaign_step_publication_success_schedule_new_publication'
            )}
          </div>

          <div
            onClick={() => {
              // navigation('/HomePage')
              publicationSuccessFinish('/HomePage')
            }}
            style={{
              backgroundColor: '#6A0098',
              padding: '8px 10px',
              borderRadius: 4,
              cursor: 'pointer',

              userSelect: 'none',

              fontSize: 16,
              fontFamily: 'Poppins',
              fontWeight: 500,
              color: '#fff',
            }}
          >
            {t(
              'new_publication_page_campaign_step_publication_success_back_to_dashboard'
            )}
          </div>
        </div>
      </div>
    )
  }

  return <div className={styles.container_header}>{ContentRender()}</div>
}

export default StepPublicationSuccess
