import { useState } from 'react'
import styles from './styles.module.css'
import { func, string, array } from 'prop-types'
import { DragContainer } from './DragContainer'
import { ImagesContainer } from './ImagesContainer'

export const DragUploadRemoveBackgroundContainer = ({
  variant,
  textButton,
  onClickButton,
  imagesArray,
  setImagesArray,
  onClickRemoveBackground,
}) => {
  const [selectedImage, setSelectedImage] = useState()

  function handleChange(e) {
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileBase64 = reader.result
        let fileDataReturn = {
          fileUrl: fileBase64,
          fileName: file.name,
          fileSize: file.size,
          fileContentType: file.type,
          fileFormat: file.type.split('/')[1],
          status: 'uploaded',
          isOriginal: true,
        }

        setSelectedImage(fileDataReturn)
      }

      reader.readAsDataURL(file)
    })
  }

  const onSelectImage = (imageToPushOnArray) => {
    console.log('onSelectImage', imageToPushOnArray)
    if (imageToPushOnArray) {
      onClickButton([...imagesArray, imageToPushOnArray])
      setSelectedImage(undefined)
    }
  }

  const handleRemoveFile = (index) => {
    console.log('handleRemoveFile', index)
    imagesArray[index].status = 'deleted'
    setImagesArray((current) => [...current])
  }
  return (
    <div className={styles.modal}>
      <div className={styles.container_modal}>
        <div
          style={{ position: 'relative' }}
          className={styles.content_up_image}
        >
          <DragContainer
            filesSelectedArray={imagesArray}
            selectedImage={selectedImage}
            onFileChange={handleChange}
            onCloseSelectedImage={() => setSelectedImage(undefined)}
          />
          <div className={styles.modal_button_save}>
            <button
              onClick={() => onSelectImage(selectedImage)}
              className={styles.button_add}
            >
              {textButton}
            </button>
          </div>
        </div>
        {imagesArray?.length !== 0 && (
          <div
            className={
              variant === 'column'
                ? styles.content_list_image_column
                : styles.content_list_image
            }
          >
            <div
              style={{ position: 'relative' }}
              className={styles.container_box_list_image}
            >
              <ImagesContainer
                imagesArray={imagesArray}
                onClickRemoveBackground={onClickRemoveBackground}
                onClickExclude={(index) => {
                  console.log('Excluindo a imagem no índice:', index)
                  console.log('VALOR DE imagesArray:', imagesArray)
                  handleRemoveFile(index)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

DragUploadRemoveBackgroundContainer.propTypes = {
  textButton: string,
  variant: string,
  onClickButton: func,
  imagesArray: array,
  setImagesArray: func,
  onClickRemoveBackground: func,
}
